import React, { useEffect, useState } from "react";
import styles from "./Pagination.module.css";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementIndex,
  decrementIndex,
  setIndex,
} from "../../store/features/paginationSlice";

const Pagination = ({ pages, style }) => {
  const pagePerShow = 10; //한 페이지당 보일 페이지 개수

  const paginationSlice = useSelector((state) => state.pagination);
  const dispatch = useDispatch();

  const [pageList, setPageList] = useState([]);

  const onClickPage = (index) => {
    dispatch(setIndex(index));
  };
  const onClickPageDown = () => {
    if (paginationSlice.index === 0) return;
    dispatch(decrementIndex());
  };
  const onClickPageUp = () => {
    if (paginationSlice.index === pages - 1) return;
    dispatch(incrementIndex());
  };

  useEffect(() => {
    setPageList((currentState) =>
      Array.from(Array(pages), (v, i) => {
        const optionObj = {};
        if (i === paginationSlice.index) {
          optionObj.isSamePaginationSliceIndex = true;
        } else {
          optionObj.isSamePaginationSliceIndex = false;
        }
        if (
          i >= parseInt(paginationSlice.index / pagePerShow) * pagePerShow &&
          i <
            parseInt(paginationSlice.index / pagePerShow) * pagePerShow +
              pagePerShow
        ) {
          optionObj.isShow = true;
        } else {
          optionObj.isShow = false;
        }
        return optionObj;
      })
    );
  }, [pages]); //pages(서버에서 받은 pages) 값이 변경될 때 실행
  useEffect(() => {
    setPageList((currentState) =>
      Array.from(Array(pages), (v, i) => {
        const optionObj = {};
        if (i === paginationSlice.index) {
          optionObj.isSamePaginationSliceIndex = true;
        } else {
          optionObj.isSamePaginationSliceIndex = false;
        }
        if (
          i >= parseInt(paginationSlice.index / pagePerShow) * pagePerShow &&
          i <
            parseInt(paginationSlice.index / pagePerShow) * pagePerShow +
              pagePerShow
        ) {
          optionObj.isShow = true;
        } else {
          optionObj.isShow = false;
        }
        return optionObj;
      })
    );
    window.scrollTo(0, 0);
  }, [paginationSlice]);
  return (
    <>
      <nav className={styles.pagination_wrap} style={style}>
        <button type={"button"} onClick={onClickPageDown}>
          <BiChevronLeft size={28} color={"#b5b5b5"} />
        </button>
        <ul>
          {pageList.map((v, i) => {
            return (
              <li
                key={i}
                className={
                  v.isSamePaginationSliceIndex === true
                    ? styles.pagination_wrap_selected
                    : null
                }
                style={{
                  display: v.isShow === true ? "flex" : "none",
                }}
                onClick={() => onClickPage(i)}
              >
                {i + 1}
              </li>
            );
          })}
        </ul>
        <button type={"button"} onClick={onClickPageUp}>
          <BiChevronRight size={28} color={"#b5b5b5"} />
        </button>
      </nav>
    </>
  );
};

export default Pagination;
