import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const tagListApi = createApi({
  reducerPath: "tagListApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
  }),
  tagTypes: ["tagList"],
  endpoints: (builder) => ({
    getAllTagLists: builder.query({
      query: ({ type }) => ({
        url: `/taglist/?type=${type}`,
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        const tagDataObj = {};
        const tagDataKeys = Object.keys(response.tagData);
        for (let i = 0; i < tagDataKeys.length; i++) {
          const arr = [];
          for (let j = 0; j < response.tagData[tagDataKeys[i]].length; j++) {
            arr.push(response.tagData[tagDataKeys[i]][j]);
          }
          tagDataObj[tagDataKeys[i]] = arr;
        }
        // console.log({ tagDataKeys, tagDataObj });
        return { tagDataKeys, tagDataObj };
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
      providesTags: ["tagList"],
    }),
  }),
});

export const { useGetAllTagListsQuery } = tagListApi;
