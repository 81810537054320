import React, { useState, useEffect } from "react";
import styles from "./CustomPage.module.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { RiErrorWarningFill } from "react-icons/ri";
import IconNavigtaion from "../../components/IconNavigation/IconNavigtaion";
import { usePostCustomMutation } from "../../store/services/customApi";

const CustomPage = () => {
  const maxCount = 10;
  const maxFileSizeByMB = 100;

  const [
    postCustom,
    {
      data: postCustomData,
      error: postCustomError,
      isLoading: isLoadingPostCustom,
    },
  ] = usePostCustomMutation();

  const [category, setCategory] = useState("");
  const [inquiryType, setInquiryType] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);

  const onChangeFiles = (e) => {
    if (e.currentTarget.files.length > 0) {
      if (e.currentTarget.files.length > 10) {
        alert(`최대 ${maxCount}까지 첨부할 수 있습니다.`);
        e.currentTarget.value = "";
        setFiles([]);
        return;
      }
      const fileArr = [];
      for (let i = 0; i < e.currentTarget.files.length; i++) {
        const fileSizeMB = (
          e.currentTarget.files[i].size /
          (1024 * 1024)
        ).toFixed(2);
        if (fileSizeMB > maxFileSizeByMB) {
          alert("파일은 최대 100MB까지 가능합니다.");
          e.currentTarget.value = "";
          setFiles([]);
          return;
        } else {
          fileArr.push(e.currentTarget.files[i]);
        }
      }
      setFiles(fileArr);
    } else {
      setFiles([]);
    }
  };
  const onSubmitCustomForm = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      formData.append("category", category);
      formData.append("inquiryType", inquiryType);
      formData.append("email", email);
      formData.append("title", title);
      formData.append("content", content);
      for (let i = 0; i < files.length; i++) {
        formData.append(`file`, files[i]);
      }
      // for (let i of formData) {
      //   console.log(i);
      // }
      await postCustom(formData);
      alert("전송완료했습니다");
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header />
      <main className={styles.main}>
        <h2>커스텀 샘플</h2>
        <hr />
        <p className={styles.caution}>
          <span>*</span> 는 필수입력사항 입니다.
        </p>
        <div className={styles.main_caution}>
          <RiErrorWarningFill size={25} color={"var(--red-color)"} />
          <p>
            문의 내용 작성 시 입력한 개인 정보 내용은 보이스픽 고객서비스 지원을
            위한 <span>개인 정보 수집 및 이용에 동의</span>한 것으로 간주됩니다.
            <br /> 입력하신 자료는 개인 정보처리 방침과 관련 법령의 보관 기간에
            따라 보관되므로 작성에 유의해 주시기 바랍니다.
            <br /> 또한, 요청사항에 따라 비용이 청구될 수 있습니다.
          </p>
        </div>
        <form className={styles.customform_wrap} onSubmit={onSubmitCustomForm}>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td className={`${styles.table_title} ${styles.required}`}>
                  분류
                </td>
                <td className={styles.table_content}>
                  <select
                    className={styles.table_selectbox}
                    value={category}
                    onChange={(e) => {
                      setCategory(e.currentTarget.value);
                    }}
                    required={true}
                  >
                    <option value={""} disabled>
                      --분류를 선택하세요--
                    </option>
                    <option value={"게임"}>게임</option>
                    <option value={"오디오북"}>오디오북</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className={`${styles.table_title} ${styles.required}`}>
                  문의 유형
                </td>
                <td className={styles.table_content}>
                  <select
                    className={styles.table_selectbox}
                    value={inquiryType}
                    onChange={(e) => {
                      setInquiryType(e.currentTarget.value);
                    }}
                    required={true}
                  >
                    <option value={""} disabled>
                      --문의 유형을 선택하세요--
                    </option>
                    <option value={"개인"}>개인</option>
                    <option value={"기업"}>기업</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className={`${styles.table_title} ${styles.required}`}>
                  이메일
                </td>
                <td className={styles.table_content}>
                  <input
                    className={styles.table_input}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.currentTarget.value);
                    }}
                    type={"email"}
                    placeholder={
                      "이메일을 입력해 주세요. ex.) voicepick@naver.com"
                    }
                    required={true}
                  />
                </td>
              </tr>
              <tr>
                <td className={`${styles.table_title} ${styles.required}`}>
                  제목
                </td>
                <td className={styles.table_content}>
                  <input
                    className={styles.table_input}
                    value={title}
                    onChange={(e) => {
                      setTitle(e.currentTarget.value);
                    }}
                    type={"text"}
                    placeholder={"제목을 입력해 주세요."}
                    required={true}
                  />
                </td>
              </tr>
              <tr>
                <td className={styles.table_title}>내용</td>
                <td className={styles.table_content}>
                  <textarea
                    className={`${styles.table_input} ${styles.table_textarea}`}
                    value={content}
                    onChange={(e) => {
                      setContent(e.currentTarget.value);
                    }}
                    placeholder={
                      "내용을 입력해주세요. (ex: 샘플대본, 캐릭터 이미지, 캐릭터 성격, 래퍼런스톤등)"
                    }
                    rows={10}
                    required={false}
                  />
                </td>
              </tr>
              <tr>
                <td className={styles.table_title}>첨부파일</td>
                <td className={styles.table_content}>
                  <input type={"file"} multiple onChange={onChangeFiles} />
                </td>
              </tr>
            </tbody>
          </table>
          <button type={"submit"}>확인</button>
        </form>
      </main>
      <Footer />
      <IconNavigtaion />
      {/* formData전송시 loadingSpinner */}
      {isLoadingPostCustom === true ? (
        <div className={styles.spinner}>
          <div></div>
        </div>
      ) : null}
    </>
  );
};

export default CustomPage;
