import React from "react";
import styles from "./IconNavigation.module.css";
import KakaoIcon from "../../assets/images/카카오톡-LOGO-06.png";
import InstagramIcon from "../../assets/images/free-icon-instagram-739193.png";
import mailIcon from "../../assets/images/free-icon-at-7291046.png";
import { BiArrowToTop } from "react-icons/bi";

const IconNavigtaion = () => {
  return (
    <nav className={styles.nav}>
      <ul>
        <li>
          <a
            href={"https://open.kakao.com/o/sfZT4PPe"}
            target={"_blank"}
            title={"카카오톡"}
            rel="noreferrer"
          >
            <img src={KakaoIcon} alt={"카카오톡 아이콘"} width={30} />
          </a>
        </li>
        <li>
          <a
            href={"https://www.instagram.com/mintmedia.seoul/"}
            target={"_blank"}
            title={"인스타그램"}
            rel="noreferrer"
          >
            <img src={InstagramIcon} alt={"인스타그램 아이콘"} width={30} />
          </a>
        </li>
        <li>
          <a href={"mailto:hrlee@mintmedia.co.kr"} title={"메일"}>
            <img src={mailIcon} alt={"메일 아이콘"} width={30} />
          </a>
        </li>
        <li onClick={() => window.scrollTo(0, 0)}>
          <span title="맨 위로" className={styles.nav_top_button}>
            <BiArrowToTop size={30} color={"var(--white-color)"} />
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default IconNavigtaion;
