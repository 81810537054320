import React, { useEffect, useRef } from "react";
import styles from "./PersonalInfoProvisionPage.module.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import IconNavigtaion from "../../components/IconNavigation/IconNavigtaion";
import { useGetProvisionQuery } from "../../store/services/provisionApi";

const PersonalInfoProvisionPage = () => {
  const { data: getProvisionData, error: getProvisionError } =
    useGetProvisionQuery({ type: "private" });

  const mainRef = useRef(null);

  useEffect(() => {
    if (getProvisionError) {
      mainRef.current.innerHTML = getProvisionError;
    } else {
      mainRef.current.innerHTML = getProvisionData ?? null;
    }
  }, [getProvisionData, getProvisionError]);
  return (
    <>
      <Header />
      <main ref={mainRef} className={styles.main_wrap}></main>
      {/* <main ref={mainRef} className={styles.main_wrap}>
        <hr />
        <h1>개인정보처리방침</h1>
        <p
          style={{
            margin: "30px 0",
          }}
        >
          주식회사 민트미디어(이하 ‘회사’)가 운영하는 보이스픽은 수집 및
          활용되는 고객의 개인정보의 효과적인 관리와 안전한 보호를 위하여 최선의
          노력을 다하고 있습니다.
        </p>
        <nav className={styles.main_wrap_nav}>
          <ul>
            <li>
              <a href={"#1"}>제1조 총칙</a>
            </li>
            <li>
              <a href={"#2"}>제2조 개인정보의 수집항목 및 수집/이용 목적</a>
            </li>
            <li>
              <a href={"#3"}>제3조 개인정보의 수집방법</a>
            </li>
            <li>
              <a href={"#4"}>제4조 개인정보의 보유 및 이용기간</a>
            </li>
            <li>
              <a href={"#5"}>제5조 개인정보의 파기절차 및 방법</a>
            </li>
            <li>
              <a href={"#6"}>제6조 개인정보 처리의 위탁</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href={"#7"}>
                제7조 개인정보 자동 수집장치의 설치 운영 및 그 거부에 관한 사항
              </a>
            </li>
            <li>
              <a href={"#8"}>제8조 개인정보의 기술적, 관리적 보호</a>
            </li>
            <li>
              <a href={"#9"}>제9조 개인정보 보호책임 부서 및 연락처</a>
            </li>
            <li>
              <a href={"#10"}>제10조 개인정보 열람청구</a>
            </li>
            <li>
              <a href={"#11"}>제 11조 권익침해 구제방법</a>
            </li>
          </ul>
        </nav>
        <h2 id="1">제 1 장 총칙</h2>
        <p>
          1. 개인정보란 생존하는 개인에 관한 정보로서 성명, 주민등록번호 등에
          의하여 당해 개인을 알아볼 수 있는 부호, 문자, 음성, 음향, 영상 및 생체
          특성 등에 관한 정보(당해 정보만으로는 특정 개인을 알아볼 수 없는
          경우에도 다른 정보와 용이하게 결합하여 알아볼 수 있는 것을 포함)를
          말합니다. <br />
          2. 회사는 고객님의 개인정보를 중요시하며, 『정보통신망 이용촉진 및
          정보보호 등에 관한 법률』과 개인정보 보호 관련 각종 법규를 준수하고
          있습니다.
          <br /> 3. 회사는 개인정보처리방침을 통하여 고객님의 개인정보가 어떠한
          용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가
          취해지고 있는지 알려드립니다.
          <br /> 4. 회사의 개인정보처리방침은 관련 법령 및 내부 운영 방침의
          변경에 따라 개정될 수 있습니다. 개인정보처리방침이 개정되는 경우에는
          시행일자 등을 부여하여 개정된 내용을 홈페이지(http://voicepick.kr)에
          지체 없이 공지합니다.
          <br /> 5. 영업의 전부 또는 일부를 양도하거나 합병 등으로 개인정보를
          이전하는 경우 서면 전자우편 등을 통하여 고객님께 개별적으로 통지하고,
          회사의 과실 없이 고객님의 연락처를 알 수 없는 경우에 해당하여 서면,
          전자우편 등으로 통지할 수 없는 경우에는 홈페이지(http://voicepick.kr),
          첫 화면에서 식별할 수 있도록 표기하여 30일 이상 그 사실을 공지합니다.
          단, 천재지변 등 정당한 사유로 홈페이지 게시가 곤란한 경우에는 2곳
          이상의 중앙일간지(고객님의 대부분이 특정 지역에 거주하는 경우에는 그
          지역을 보급구역으로 하는 일간지로 할 수 있습니다.)에 1회 이상 공고하는
          것으로 갈음합니다.
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"2"}
        >
          제2조 개인정보의 수집항목 및 수집/이용 목적
        </h2>
        <p>
          1. 회사는 상담, 서비스 제공 등을 위하여 필요한 범위에서 최소한의
          개인정보만을 수집합니다.
          <br />
          2. 사상, 신념, 가족 및 친인척관계, 학력(學歷)•병력(病歷), 기타
          사회활동 경력 등 고객님의 권리 • 이익이나 사생활을 뚜렷하게 침해할
          우려가 있는 개인정보는 수집하지 않습니다. 다만, 고객님께서 수집에
          동의하시거나 다른 법률에 따라 특별히 수집 대상 개인정보로 허용된
          경우에는 필요한 범위에서 최소한으로 위 개인정보를 수집할 수 있습니다.
          <br />
          3. 회사는 단말기 접근 권한을 허용 받았어도 허용된 권한 관련 모든
          정보가 즉시 수집(전송)되는 것은 아니며, 개인정보 수집 이용 동의를 받은
          범위 내에서, 고객님 단말기 정보에 접근 후에 수집(전송)합니다.
          <br />
          4. 회사는 아래의 경우에 한하여 고객님의 동의 없이 고객님의 개인정보를
          수집ㆍ이용할 수 있습니다.
          <br />
          <span>
            가. 고객님과의 계약을 이행하기 위하여 필요한 개인정보로서
            경제적ㆍ기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한
            경우
          </span>
          <br />
          <span>
            나. 서비스의 제공에 따른 요금정산을 위하여 필요한 경우 다. 관련
            법령에 특별한 규정이 있는 경우
          </span>
        </p>
        <p
          style={{
            marginTop: "30px",
          }}
        >
          [필수]
        </p>
        <table
          className={styles.table_wrap}
          style={{
            margin: "10px 0",
          }}
        >
          <thead>
            <tr>
              <td>목적</td>
              <td>항목</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>사용자 인증</td>
              <td>서비스 이용</td>
            </tr>
            <tr>
              <td>휴대폰 번호, 이메일 주소, 성함</td>
              <td>서비스 이용시간/정지 기록, 접속 로그, 쿠키 정보, 생년월일</td>
            </tr>
          </tbody>
        </table>
        <p>
          ※ 보이스픽 서비스 제공을 위해서 필요한 최소한의 개인정보이므로 동의
          거부 시 서비스 이용이 제한될 수 있습니다.
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"3"}
        >
          제3조 개인정보의 수집방법
        </h2>
        <p>
          회사는 다음과 같은 방법으로 개인정보를 수집합니다.
          <br />
          <span>가. 홈페이지를 통한 문의 및 제휴문의 과정에서 수집</span>
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"4"}
        >
          제4조 개인정보의 보유 및 이용기간
        </h2>
        <p>
          1. 회사는 고객님의 개인정보를 고객님께 서비스를 제공하는 기간 또는
          분쟁처리 기간 동안에 한하여 보유하고 이를 이용합니다.
          <br />
          <span>
            가. 요금정산, 요금 과오납 등 분쟁 대비를 위해 해지 또는 요금완납 후
            6개월까지
          </span>
          <br />
          <span>
            나. 요금관련 분쟁 등 서비스 이용과 관련된 민원/분쟁이 계속될
            경우에는 민원/분쟁 해결 시까지 보유(단, 관계 법령에 특별한 규정이
            있을 경우 관련 법령에 따라 보관)
          </span>
          <br />
          2. 상법, 국세기본법, 통신비밀보호법, 전자상거래 등에서의 소비자보호에
          관한 법률, 신용정보의 이용 및 보호에 관한 법률 등 관계법령에 따라
          보존할 필요가 있는 경우 회사는 관계법령에서 정한 기간 동안 고객의
          개인정보를 보유하며, 그 기간은 다음과 같습니다.
        </p>
        <table className={styles.table_wrap}>
          <thead>
            <tr>
              <td>보존 근거</td>
              <td
                style={{
                  width: "619px",
                }}
              >
                보존 대상
              </td>
              <td>보존 기간</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={2}>상법</td>
              <td>상업장부와 영업에 관한 중요서류에 포함된 개인정보</td>
              <td>10년</td>
            </tr>
            <tr>
              <td>전표 또는 이와 유사한 서류에 포함된 개인정보</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>국세기본법</td>
              <td>
                성명, 주민번호, 전화번호, 청구지 주소, 요금납부내역 등 거래에
                관한 장부 및 증거서류
              </td>
              <td>5년</td>
            </tr>
            <tr>
              <td rowSpan={2}>통신비밀보호법</td>
              <td>
                통신사실확인자료 제공에 필요한 성명, 주민번호, 전화번호 및
                가입자의 전기통신일시, 전기통신개시ㆍ종료시간, 발ㆍ착신 통신번호
                등 상대방의 가입자번호, 사용도수, 발신기지국의 위치정보
              </td>
              <td>
                12개월
                <br /> 단, 시외/시내전화 역무와 관련된 자료 : 6개월
              </td>
            </tr>
            <tr>
              <td>
                컴퓨터통신 또는 인터넷의 로그기록자료, 정보통신기기의 위치를
                확인할 수 있는 접속지의 추적자료
              </td>
              <td>3개월</td>
            </tr>
            <tr>
              <td rowSpan={4}>전자상거래 등에서의 소비자보 호에 관한 법률</td>
              <td>계약 또는 청약철회 등에 관한 기록</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>대금결제 및 재화 등의 공급에 관한 기록</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
              <td>3년</td>
            </tr>
            <tr>
              <td>표시/광고에 관한 기록</td>
              <td>6개월</td>
            </tr>
            <tr>
              <td>신용정보의 이용 및 보호에 관한 법률</td>
              <td>신용정보의 수집/처리 및 이용 등에 관한 기록</td>
              <td>3년</td>
            </tr>
          </tbody>
        </table>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"5"}
        >
          제5조 개인정보의 파기절차 및 방법
        </h2>
        <p>
          회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당
          정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
          <br />
          1. 파기절차
          <br />
          <span>
            가. 고객님의 개인정보는 수집 및 이용목적이 달성된 후 별도의 DB로
            옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
            정보보호 사유(보유 및 이용기간 참조)에 따라 일정 기간 저장된 후
            파기됩니다.
          </span>
          <br />
          <span>
            나. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우와 재가입시 가입비
            면제 대상여부를 확인하는 경우가 아니고서는 보유되는 이외의 다른
            목적으로 이용되지 않습니다.
          </span>
          <br />
          2. 파기방법 <br />
          <span>
            가. 종이(서면)에 작성•출력된 개인정보 : 분쇄하거나 소각 등의
            방법으로 파기
          </span>
          <br />
          <span>
            나. DB 등 전자적 파일 형태로 저장된 개인정보 : 재생할 수 없는 기술적
            방법으로 삭제
          </span>
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"6"}
        >
          제6조 개인정보 처리의 위탁
        </h2>
        <p>
          회사는 서비스 제공에 관한 계약을 이행하고 고객님의 편의 증진 등을
          위하여 개인정보 처리업무를 외부 전문업체에게 위탁하고 있으며, 수탁자에
          대해서는 “위.수탁계약서” 등을 통하여 관련 법규 및 지침의 준수,
          정보보호 및 비밀유지, 제3자 제공 금지, 사고 시 책임부담, 위탁기간 종료
          즉시 개인정보의 반납/파기 의무 등을 규정하여 관리하고 있습니다.
        </p>
        <p
          style={{
            marginTop: "30px",
          }}
        >
          ㅇ 개인정보 처리업무 수탁자 및 위탁 업무 내용
        </p>
        <table
          className={styles.table_wrap}
          style={{
            margin: "10px 0",
          }}
        >
          <thead>
            <tr>
              <td>수탁사</td>
              <td>위탁업무내용</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                서비스 운영 및 고객상담업무 위탁운영
                <br />
                시스템 구축 및 유지보수, 보이스픽 부가서비스 제공
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          ※ 수탁사 리스트는 해당 서비스 변경 및 계약기간에 따라 변경될 수
          있습니다.
        </p>
        <p>
          ※ 보이스픽 서비스 제공을 위해서 필요한 최소한의 개인정보이므로 동의를
          반드시 해주셔야 정상적으로 서비스를 이용하실 수 있습니다.
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"7"}
        >
          제7조 개인정보 자동 수집장치의 설치 운영 및 그 거부에 관한 사항
        </h2>
        <p>
          회사는 홈페이지 운영에 있어 필요 시 고객님의 정보를 찾아내고 저장하는
          '쿠키(Cookie)'를 운용합니다. 쿠키는 회사의 웹사이트를 운영하는데
          이용되는 서버가 고객님의 브라우저에 보내는 아주 작은 텍스트 파일로서
          고객님의 컴퓨터 하드디스크에 저장됩니다. 고객님께서는 웹브라우저의
          보안 정책을 통해 쿠키에 의한 정보수집의 허용 거부 여부를 결정하실 수
          있습니다.
          <br />
          1. 쿠키에 의해 수집되는 정보 및 이용 목적 <br />
          <span>
            가. 수집 정보 : 접속IP, 접속로그, 이용 컨텐츠 등 서비스 이용정보
          </span>
          <br />
          <span>
            나. 이용목적 ㅇ 고객님의 관심분야에 따라 차별화된 정보를 제공 ㅇ
            회원과 비회원의 접속 빈도나 방문 시간, 로그인 유무, 서비스 이용
            형태를 분석하여 개인 이력 정보를 제공하며 서비스 개편의 척도로
            활용합니다.
          </span>
          <br />
          2. 고객님은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서
          웹브라우저에서 옵션을 설정함으로써 쿠키에 의한 정보 수집 수준의 선택을
          조정하실 수 있습니다.
          <br />
          <span>
            가. 웹브라우저의 [도구]메뉴 [인터넷옵션]-{">"}[개인정보]에서 쿠키에
            의한 정보 수집 수준을 정할 수 있습니다.
          </span>
          <br />
          <span>
            나. 위에 제시된 메뉴를 통해 쿠키가 저장될 때마다 확인을 하거나,
            아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, 고객님께서 쿠키
            설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
          </span>
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"8"}
        >
          제8조 개인정보의 기술적, 관리적 보호
        </h2>
        <p>
          회사는 고객님의 개인정보가 분실, 유출, 위조•변조 또는 훼손되지 않도록
          안전성 확보를 위하여 다음과 같은 기술적•관리적 대책을 마련하고
          있습니다.
          <br />
          <br />
          <br />
          [기술적 보호대책] <br /> 1. 고객님의 개인정보를 암호화하여 저장하며,
          정보통신망을 통해 고객님의 개인정보 및 인증정보를 송수신 하는 경우에는
          안전한 보안서버 구축 등의 조치를 통해 암호화하여 보호하고 있습니다.
          <br /> 2. 백신 소프트웨어를 이용하여 컴퓨터바이러스 등에 의한 피해를
          방지하기 위한 조치를 취하고 있습니다. 백신 소프트웨어는 주기적으로
          업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를
          도입, 적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다. <br />
          3. 고객님의 개인정보에 대한 불법적인 접근을 차단하기 위해, 외부로부터
          접근이 통제된 구역에 시스템을 설치하고, 침입을 차단하는 장치를
          이용하고 있으며, 아울러 침입탐지시스템을 설치하여 24시간 침입을
          감시하고 있습니다. <br /> <br /> <br /> [관리적 보호대책] <br /> 1.
          회사는 고객님의 개인정보를 안전하게 처리하기 위한 내부관리계획을
          마련하여 소속 직원이 이를 숙지하고 준수하도록 하고 있습니다. <br /> 2.
          회사는 고객님의 개인정보를 처리할 수 있는 자를 최소한으로 제한하고
          접근 권한을 관리하며, 새로운 보안 기술 습득 및 개인정보보호 의무 등에
          관해 정기적인 사내 교육과 외부 위탁교육을 통하여 법규 및 정책을 준수할
          수 있도록 하고 있습니다. 고객님의 개인정보를 처리하는 자는 다음과
          같습니다.
          <br />
          <span>
            가. 고객님을 직•간접적으로 상대하여 마케팅 업무를 수행하는 자
          </span>
          <br />
          <span>
            나. 개인정보보호책임자 및 개인정보보호담당자 등 개인정보 보호 및
            개인정보보호 업무를 담당하는 자
          </span>
          <br />
          <span>다. 기타 업무상 개인정보의 처리가 불가피한 자</span>
          <br />
          3. 전 임직원이 정보보호서약서에 서명하게 함으로써 직원에 의한
          정보(개인정보 포함)유출을 사전에 방지하고, 수시로 개인정보보호 의무를
          상기시키며 준수 여부를 감사하기 위한 내부 절차를 마련하여 시행하고
          있습니다. <br /> 4. 개인정보 취급자의 업무 인수인계는 보안이 유지된
          상태에서 철저하게 이루어지도록 하여, 입사 및 퇴사 후 개인정보
          침해사고에 대한 책임을 명확하게 규정하고 있습니다. <br />
          5. 전산실 및 자료보관실 등을 통제구역으로 설정하여 출입을 통제합니다.
          <br /> 6. 회사는 고객님 개인의 실수나 기본적인 인터넷의 위험성 때문에
          일어나는 일들에 대해 책임을 지지 않습니다.
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"9"}
        >
          제9조 개인정보 보호책임 부서 및 연락처
        </h2>
        <p>
          회사는 고객님의 개인정보를 보호하고 개인정보와 관련한 불만 및
          문의사항을 처리하기 위하여 아래와 같이 관련 부서를 지정하여 운영하고
          있습니다. 또한 고객님의 의견을 매우 소중하게 생각합니다. 고객님께서
          보이스픽 서비스의 개인정보 관련 문의사항이 있을 경우 아래 개인정보
          보호책임자 및 담당자에게 문의하시면 신속하고 성실하게 답변을
          드리겠습니다.
          <br />
          <br />
          <br /> 1. 개인정보 보호책임 부서 및 연락처
          <br />
          <span>
            가. 보이스픽 개인정보보호책임자 : ㈜민트미디어 / 이학래 대표
          </span>
          <br />
          <span>ㅇ 이메일 : hrlee@mintmedia.co.kr</span>
          <br /> 2. 개인정보 보호책임자 역할
          <br />
          <span>가. 개인정보보호 관련 주요 지침/정책/제도 수립 시행</span>
          <br />
          <span>나. 개인정보 유출, 오남용 방지를 위한 내부통제시스템 구축</span>
          <br />
          <span>다. 개인정보보호 분야 관리실태 감사 및 지도점검</span>
          <br />
          <span>라. 개인정보보호 교육 계획 수립 시행</span>
          <br />
          <span>마. 개인정보처리방침 수립 개정</span>
          <br />
          <span>
            바. 개인정보 침해사고 예방 대응 사. 기타 기타정보보호를 위하여
            필요한 업무
          </span>
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"10"}
        >
          제10조 개인정보 열람청구
        </h2>
        <p>
          보이스픽은 고객님의 개인정보 열람 청구를 위하여 아래와 같이 관련
          부서를 지정하여 운영하고 있습니다. 고객님께서 보이스픽 서비스에
          개인정보 열람을 청구하실 경우 아래 부서 담당자에게 문의하시면 신속하고
          성실하게 답변 드리겠습니다.
          <br />
          <br />
          <br />
          [보이스픽 개인정보 열람청구 접수∙처리 부서]
          <br />
          가. 담당자 : 이학래
          <br />
          나. 연락처 : hrlee@mintmedia.co.kr
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"11"}
        >
          제 11조 권익침해 구제방법
        </h2>
        <p>
          기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
          문의하시기 바랍니다.
          <br />
          1. 개인정보 침해신고센터(한국인터넷진흥원 운영)
          <br />
          <span> 가. 소관업무 : 개인정보 침해사실 신고, 상담 신청</span>
          <br />
          <span>
            나. 홈페이지/전화 : http://privacy.kisa.or.kr / (국번없이)118
          </span>
          <br />
          2. 개인정보 분쟁조정위원회
          <br />
          <span>
            가. 소관업무 : 개인정보 분쟁조정신청,집단분쟁조정(민사적 해결)
          </span>
          <br />
          <span> 나. 홈페이지/전화 : www.kopico.go.kr/(국번없이)1833-6972</span>
          <br /> 3. 대검찰청 사이버수사과 : www.spo.go.kr / (국번없이) 1301
          <br />
          4. 경찰청 사이버안전국 : cyberbureau.police.go.kr / (국번없이) 182
        </p>
      </main> */}
      <Footer />
      <IconNavigtaion />
    </>
  );
};

export default PersonalInfoProvisionPage;
