import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const voiceApi = createApi({
  reducerPath: "voiceApi",
  tagTypes: ["voice"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
  }),
  endpoints: (builder) => ({
    getRandomVoices: builder.query({
      query: () => ({
        url: "/voice/random/",
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        const voiceList = [];
        for (let i = 0; i < response.voiceList.length; i++) {
          voiceList.push({ ...response.voiceList[i], isPlay: false });
        }
        return { voiceList };
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
      providesTags: ["voice"],
    }),

    getAllVoices: builder.query({
      query: ({ pageIndex, category }) => ({
        url: `/voice/?page=${pageIndex}&category=${category}`,
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        const voiceList = [];
        for (let i = 0; i < response.voiceList.length; i++) {
          voiceList.push({ ...response.voiceList[i], isPlay: false });
        }
        return { voiceList, pages: response.pages };
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
      providesTags: ["voice"],
    }),

    postTagLists: builder.mutation({
      query: ({ filterList, pageIndex, category }) => ({
        url: `/filter/voice/?page=${pageIndex}&category=${category}`,
        method: "POST",
        body: {
          tagFind: filterList,
        },
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        const voiceList = [];
        for (let i = 0; i < response.voiceList.length; i++) {
          voiceList.push({ ...response.voiceList[i], isPlay: false });
        }
        return { voiceList, pages: response.pages };
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
    }),
  }),
});

export const {
  useLazyGetAllVoicesQuery,
  useGetRandomVoicesQuery,
  usePostTagListsMutation,
} = voiceApi;
