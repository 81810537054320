import React, { useEffect, useState } from "react";
import styles from "./Popup.module.css";
import { useGetAllPopupQuery } from "../../store/services/popupApi";
import { setCookie, getCookie, deleteCookie } from "../../utils/cookie";

const Popup = () => {
  const { data, error, isFetching } = useGetAllPopupQuery(null);

  const [popupList, setPopupList] = useState();

  const onClickCloseButton = (id) => {
    setPopupList((currentState) => {
      const newState = [];
      for (let i = 0; i < currentState.length; i++) {
        if (id === currentState[i].id) {
          newState.push({ ...currentState[i], show: false });
        } else {
          newState.push({ ...currentState[i] });
        }
      }
      return newState;
    });
  };

  const onChangeDontShowToday = (e) => {
    console.log(e.currentTarget.checked, e.currentTarget.dataset.id);
    if (e.currentTarget.checked === true) {
      const now = new Date();
      setCookie(e.currentTarget.dataset.id, "popup", {
        expires: new Date(
          `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}T24:00:00`
        ).toUTCString(),
      });
    } else {
      deleteCookie(e.currentTarget.dataset.id);
    }
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      const popupList = [];
      for (let i = 0; i < data.length; i++) {
        if (getCookie(data[i].id.toString()) === undefined) {
          popupList.push(data[i]);
        }
      }
      setPopupList(popupList);
    }
  }, [data]);

  return (
    <>
      {Array.isArray(popupList)
        ? popupList.map((v) => {
            return (
              <div
                key={v.id}
                className={styles.popup}
                style={{
                  display: v.show === true ? "flex" : "none",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_BASEURL}${v.file}`}
                  style={{
                    width: "100%",
                    height: "50vh",
                    objectFit: "contain",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 3px",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{
                        cursor: "pointer",
                      }}
                      data-id={v.id}
                      onChange={onChangeDontShowToday}
                    />
                    <p>오늘 하루 안보기</p>
                  </label>
                  <p
                    style={{
                      cursor: "pointer",
                      alignSelf: "flex-end",
                    }}
                    onClick={() => onClickCloseButton(v.id)}
                  >
                    닫기
                  </p>
                </div>
              </div>
            );
          })
        : null}
    </>
  );
};

export default Popup;
