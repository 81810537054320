import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const customApi = createApi({
  reducerPath: "customApi",
  tagTypes: ["custom"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
  }),
  endpoints: (builder) => ({
    postCustom: builder.mutation({
      query: (formData) => ({
        url: "/custom/",
        method: "POST",
        body: formData,
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        return response.message;
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
      invalidatesTags: ["custom"],
    }),
  }),
});

export const { usePostCustomMutation } = customApi;
