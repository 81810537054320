import React, { useEffect, useState } from "react";
import styles from "./ActorPage.module.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useParams, useNavigate } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";
import { useGetActorQuery } from "../../store/services/actorApi";
import { useDispatch, useSelector } from "react-redux";
import VoiceList from "../../components/VoiceList/VoiceList";
import Pagination from "../../components/Pagination/Pagination";
import { setIndex } from "../../store/features/paginationSlice";

const ActorPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // console.log(id);
  const paginationSlice = useSelector((state) => state.pagination);
  const dispatch = useDispatch();

  const {
    data: getActorData,
    error: getActorError,
    isFetching: isFetchingGetActor,
  } = useGetActorQuery(
    { id, pageIndex: paginationSlice.index },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [voiceData, setVoiceData] = useState(null);

  useEffect(() => {
    if (getActorData !== undefined) {
      setVoiceData(getActorData.voiceData);
    }
  }, [getActorData]);
  useEffect(() => {
    dispatch(setIndex(0));
    return () => {
      dispatch(setIndex(0));
    };
  }, []);

  return (
    <>
      <Header />
      <main className={styles.main}>
        <nav className={styles.back_nav}>
          <BiChevronLeft
            size={29}
            color={"var(--main-color)"}
            onClick={() => navigate("/actor")}
          />
          <p>리스트로 돌아가기</p>
        </nav>
        {isFetchingGetActor ? null : getActorError ? (
          <>{getActorError}</>
        ) : getActorData ? (
          <>
            {/* 성우 정보 */}
            <div className={styles.actorinfo_wrap}>
              <div className={styles.actordata_wrap}>
                <img
                  alt={
                    getActorData.actorData.photo?.split("/")[
                      getActorData.actorData.photo?.split("/").length - 1
                    ] ?? "undefined"
                  }
                  src={`${process.env.REACT_APP_BASEURL}${getActorData.actorData.photo}`}
                  width={304}
                  height={304}
                  decoding={"async"}
                />
                <h2>
                  성우 <strong>{getActorData.actorData.name}</strong>
                </h2>
                <p>{getActorData.actorData.profile}</p>
              </div>
              <div className={styles.voicedata_wrap}>
                <hr />
                <h2>주요 출연작품</h2>
                <ul className={styles.piecelist_ul}>
                  {Array.isArray(getActorData.actorData.portfolio) === true
                    ? getActorData.actorData.portfolio.map((v) => {
                        return <li key={v}>{v}</li>;
                      })
                    : null}
                </ul>
                <hr />
                <h2>카테고리</h2>
                <ul className={styles.taglist_ul}>
                  {Array.isArray(getActorData.actorData.tag) === true
                    ? getActorData.actorData.tag.map((v) => {
                        return <li key={v}>{v}</li>;
                      })
                    : null}
                </ul>
              </div>
            </div>
            {/* 성우 작업물 */}
            <hr
              style={{
                marginTop: "120px",
              }}
            />
            <h2>
              <strong>성우 작업물</strong>
            </h2>
            {voiceData !== null ? (
              <>
                <ul className={styles.voice_list}>
                  {Array.isArray(voiceData.voiceList) === true
                    ? voiceData.voiceList.map((v, i) => (
                        <li key={v.id}>
                          <VoiceList
                            voiceData={v}
                            index={i}
                            parentStateSetter={setVoiceData}
                          />
                        </li>
                      ))
                    : null}
                </ul>
                <Pagination
                  pages={voiceData.pages}
                  style={{
                    display: voiceData.pages ? "flex" : "none",
                    marginTop: "122px",
                  }}
                />
              </>
            ) : null}
          </>
        ) : null}
      </main>
      <Footer />
    </>
  );
};

export default ActorPage;
