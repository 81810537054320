import React, { useEffect, useRef, useState } from "react";
import styles from "./VoiceList.module.css";
import playButtonImage from "../../assets/images/그룹 3822.png";
import pauseButtonImage from "../../assets/images/일시정지.png";
import { useNavigate } from "react-router-dom";

const VoiceList = ({ voiceData, index, parentStateSetter }) => {
  // voiceData 는 부모요소로 받은 voiceList고
  // parentStateSetter는 부모요소로부터 받은 voiceData:{ voiceList : [], pages ?: Number, total ?: Number }를 바꾸는 setter함수다

  const progressBarOutSideCount = 125; //progressBar 바깥 쪽 bar의 개수
  const progressBarInsidePercent = {
    0: "0",
    1: "20%",
    2: "40%",
    3: "60%",
    4: "80%",
    5: "100%",
  };
  // progressBar 내부 퍼센트
  // 이거 바꾸면 리스트 요소 VoiceList.module.css내부 progress_show의 width : 6px도 수정해야함(개수 맞춰야됨)

  const navigate = useNavigate();

  const [progressBarArr, setProgressBarArr] = useState(
    Array.from(Array(progressBarOutSideCount), (v, i) => {
      if (i % 2 === 0) {
        return Array.from(Array(6), () => false); // 보이는 progressBar
      } else {
        return Array.from(Array(4), () => false); // 안보이는 progressBar
      }
    })
  ); //progressBar 랜더링
  const [animationDelayArr, setAnimationDelayArr] = useState(() => {
    const arr = [];
    for (let i = 0; i < progressBarArr.length; i++) {
      for (let j = 0; j < progressBarArr[i].length; j++) {
        const random = Math.random();
        const initialScaleY = random < 0.4 ? 0.4 : random;
        arr.push({ delay: Math.random(), initialScaleY });
      }
    }
    return arr;
  }); // 애니메이션 효과(딜레이, 시작height길이) - progressBarArr state선언 및 초기화 이후 progressBarArr getter에 접근하여 가능한 문법
  const [duration, setDuration] = useState(null); // 오디오 완료 시간
  const [currentTime, setCurrentTime] = useState(null); // 오디오 현재 재생 시간
  const [isAudioLoaded, setIsAudioLoaded] = useState(false); // 오디오 로딩 완료 여부
  const [audioError, setAudioError] = useState(false); // 오디오 에러
  const audioRef = useRef();

  const fixedTime = (value) => {
    let intValue = parseInt(value);
    let minute = null;
    let second = null;
    minute = parseInt(intValue / 60);
    second = intValue % 60;
    return `${minute < 10 ? `0${minute}` : minute}:${
      second < 10 ? `0${second}` : second
    }`;
  }; // UI에 렌더링되는 시간으로 바꿔주는 함수

  const onClickPlayButton = () => {
    if (audioError === true) {
      alert("오디오를 재생할 수 없습니다. 고객센터에 문의해주세요.");
      return;
    }
    if (isAudioLoaded === false) {
      alert("오디오 정보를 불러오는 중입니다. 잠시 후 다시 실행해 주세요.");
      return;
    }
    parentStateSetter((currentState) => {
      const voiceList = [];
      for (let i = 0; i < currentState.voiceList.length; i++) {
        if (i === index) {
          if (currentState.voiceList[i].isPlay === true) {
            voiceList.push({ ...currentState.voiceList[i], isPlay: false });
          } else {
            voiceList.push({ ...currentState.voiceList[i], isPlay: true });
          }
        } else {
          voiceList.push({ ...currentState.voiceList[i], isPlay: false });
        }
      }
      const result = { voiceList };
      if ("pages" in currentState) {
        result.pages = currentState.pages;
      }
      if ("total" in currentState) {
        result.total = currentState.total;
      }
      return result;
    });
  };
  const onTimeUpdateAudio = (e) => {
    setCurrentTime(e.currentTarget.currentTime);
  };
  const onEndedAudio = () => {
    parentStateSetter((currentState) => {
      const voiceList = [];
      for (let i = 0; i < currentState.voiceList.length; i++) {
        voiceList.push({ ...currentState.voiceList[i], isPlay: false });
      }
      const result = { voiceList };
      if ("pages" in currentState) {
        result.pages = currentState.pages;
      }
      if ("total" in currentState) {
        result.total = currentState.total;
      }
      return result;
    });
  };
  const onLoadedDataAudio = (e) => {
    // console.log("audio ready");
    setCurrentTime(e.currentTarget.currentTime);
    setDuration(e.currentTarget.duration);
    setIsAudioLoaded(true);
  };
  const onErrorAudio = (e) => {
    if (e.currentTarget.error === null) return;
    console.log("audio error : ", e.currentTarget.error);
    parentStateSetter((currentState) => {
      const voiceList = [];
      for (let i = 0; i < currentState.voiceList.length; i++) {
        voiceList.push({ ...currentState.voiceList[i], isPlay: false });
      }
      const result = { voiceList };
      if ("pages" in currentState) {
        result.pages = currentState.pages;
      }
      if ("total" in currentState) {
        result.total = currentState.total;
      }
      return result;
    });
    setAudioError(true);
  };

  useEffect(() => {
    if (voiceData.isPlay === true) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [voiceData]); // 부모요소로 받은 voiceData에 isPlay의 값에 따른 재생여부
  useEffect(() => {
    const percent = parseInt((currentTime / duration) * 100);
    let count = 0;
    let outSideArrIndex = 0;
    let inSideArrIndex = 0;
    for (let i = 0; i < progressBarArr.length; i++) {
      for (let j = 0; j < progressBarArr[i].length; j++) {
        if (parseInt((count / animationDelayArr.length) * 100) < percent) {
          outSideArrIndex = i;
          inSideArrIndex = j;
          count++;
        } else {
          break;
        }
      }
    } // 현재 진행상황에 대한 퍼센트 계산
    // console.log(outSideArrIndex,inSideArrIndex)
    setProgressBarArr((currentState) =>
      Array.from(Array(progressBarOutSideCount), (v, i) => {
        if (i % 2 === 0) {
          return Array.from(Array(6), () => false);
        } else {
          return Array.from(Array(4), () => false);
        }
      })
    ); // progressBar 초기화
    setProgressBarArr((currentState) => {
      for (let i = 0; i < currentState.length; i++) {
        for (let j = 0; j < currentState[i].length; j++) {
          currentState[i][j] = true;
          if (i === outSideArrIndex && j === inSideArrIndex) {
            return currentState;
          }
        }
      }
      return currentState;
    }); // progressBar 색상 변경
  }, [currentTime]); // 재생시간 변경될때마다 실행
  useEffect(() => {
    return () => {
      parentStateSetter((currentState) => {
        const voiceList = [];
        for (let i = 0; i < currentState.voiceList.length; i++) {
          voiceList.push({ ...currentState.voiceList[i], isPlay: false });
        }
        const result = { voiceList };
        if ("pages" in currentState) {
          result.pages = currentState.pages;
        }
        if ("total" in currentState) {
          result.total = currentState.total;
        }
        return result;
      });
    }; // componentWillUnmount시점 play()오류 발생을 방지
  }, []);
  return (
    <>
      <div className={styles.li_wrap}>
        <div className={styles.li_wrap_topdiv}>
          <div>
            <p className={styles.topdiv_title}>{voiceData.title}</p>
            <p
              onClick={() => navigate(`/actor/${voiceData.actorID_id}`)}
              className={styles.topdiv_user}
            >
              {voiceData.actor_name}
            </p>
          </div>
          <img
            className={styles.voiceplay_button}
            onClick={onClickPlayButton}
            src={voiceData.isPlay === true ? pauseButtonImage : playButtonImage}
            alt={"재생버튼"}
            width={50}
          />
          <p>{currentTime === null ? "--:--" : fixedTime(currentTime)}</p>
          <ul className={styles.progress_wrap}>
            {progressBarArr.map((v, i) => {
              return (
                <li
                  key={i}
                  data-index={i}
                  className={
                    i % 2 === 0 ? styles.progress_show : styles.progress_hidden
                  }
                  style={{
                    background:
                      i % 2 === 0
                        ? v.includes(true) === true
                          ? `linear-gradient(to right, var(--main-color) ${
                              progressBarInsidePercent[v.lastIndexOf(true)]
                            }, #ddd ${
                              progressBarInsidePercent[v.lastIndexOf(true)]
                            })`
                          : `linear-gradient(to right, var(--main-color) 0%, #ddd 0%)`
                        : `transparent`,
                    animationDelay: `${animationDelayArr[i].delay}s`,
                    animationPlayState:
                      voiceData.isPlay === true ? "running" : "paused",
                    transform: `scaleY(${animationDelayArr[i].initialScaleY})`,
                  }}
                ></li>
              );
            })}
          </ul>
          <p>{duration === null ? "--:--" : fixedTime(duration)}</p>
        </div>
        <ul className={styles.genre_ul}>
          {Array.isArray(voiceData.tag) ? (
            voiceData.tag.map((v, i) => {
              return <li key={i}>{v}</li>;
            })
          ) : (
            <></>
          )}
        </ul>
        <audio
          controls={false}
          ref={audioRef}
          onLoadedData={onLoadedDataAudio}
          onTimeUpdate={onTimeUpdateAudio}
          onEnded={onEndedAudio}
          onError={onErrorAudio}
          preload={"metadata"}
          src={`${process.env.REACT_APP_BASEURL}${voiceData.fileName}`}
        >
          {/* <source
            src={`${process.env.REACT_APP_BASEURL}${voiceData.fileName}`}
            type={"audio/mpeg"}
          />
          <source
            src={`${process.env.REACT_APP_BASEURL}${voiceData.fileName}`}
            type={"audio/wav"}
          />
          <source
            src={`${process.env.REACT_APP_BASEURL}${voiceData.fileName}`}
            type={"audio/ogg"}
          /> */}
        </audio>
      </div>
    </>
  );
};

export default VoiceList;
