import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const popupApi = createApi({
  reducerPath: "popupApi",
  tagTypes: ["popup"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
  }),
  endpoints: (builder) => ({
    getAllPopup: builder.query({
      query: () => ({
        url: `/popup/`,
      }),
      transformResponse: (response, meta, arg) => {
        console.log(response);
        const result = [];
        for (let i = 0; i < response.popupList.length; i++) {
          result.push({ ...response.popupList[i], show: true });
        }
        console.log(result);
        return result;
      },
      transformErrorResponse: (response, meta, arg) => {
        console.log(response);
        return response.status;
      },
      providesTags: ["popup"],
    }),
  }),
});

export const { useGetAllPopupQuery } = popupApi;
