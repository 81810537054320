import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setCategory } from "../../store/features/voiceCategorySlice";
import { useNavigate, useLocation } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import mainLogo from "../../assets/images/보이스픽 로고02.png";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const voiceCategorySlice = useSelector((state) => state.voiceCategory);
  const dispatch = useDispatch();

  const [searchInputValue, setSearchInputValue] = useState("");
  const [showVoiceCategory, setShowVoiceCategory] = useState(false);

  const onChangeSearchInputValue = (e) => {
    setSearchInputValue(e.currentTarget.value);
  };
  const onSubmitSearchInput = (e) => {
    e.preventDefault();
    navigate(`/search/${searchInputValue}`);
  };

  // useEffect(() => {
  //   if (/^\/voice/i.test(pathname) === true) {
  //     setShowVoiceCategory(true);
  //   }
  // }, [showVoiceCategory]);
  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 9999,
      }}
    >
      <header className={styles.header}>
        <div className={styles.header_wrap}>
          <ul>
            <li
              onClick={() => {
                navigate("/");
              }}
            >
              <img src={mainLogo} width={216} alt={"보이스픽로고"} />
            </li>
            <li
              className={styles.header_nav}
              onClick={() => {
                navigate("/voice");
              }}
              onMouseEnter={() => setShowVoiceCategory(true)}
              onMouseLeave={() => setShowVoiceCategory(false)}
              style={{
                borderBottom:
                  /^\/voice/i.test(pathname) === true &&
                  `5px solid var(--white-color)`,
              }}
            >
              <span>음성 샘플</span>
              <nav
                className={styles.nav}
                style={{
                  display: showVoiceCategory === true ? "flex" : "none",
                }}
              >
                <ul>
                  <li
                    style={{
                      color:
                        voiceCategorySlice.category === "charactor"
                          ? "#009398"
                          : "#b5b5b5",
                    }}
                    onClick={() => {
                      dispatch(setCategory("charactor"));
                      if (/^\/voice/i.test(pathname) === false) {
                        navigate("/voice");
                      }
                    }}
                  >
                    캐릭터
                  </li>
                  <li
                    style={{
                      color:
                        voiceCategorySlice.category === "narration"
                          ? "#009398"
                          : "#b5b5b5",
                    }}
                    onClick={() => {
                      dispatch(setCategory("narration"));
                      if (/^\/voice/i.test(pathname) === false) {
                        navigate("/voice");
                      }
                    }}
                  >
                    나레이션
                  </li>
                </ul>
              </nav>
            </li>
            <li
              className={styles.header_nav}
              onClick={() => {
                navigate("/actor");
              }}
              style={{
                borderBottom:
                  /^\/actor/i.test(pathname) === true &&
                  `5px solid var(--white-color)`,
              }}
            >
              <span>성우</span>
            </li>
            <li
              className={styles.header_nav}
              onClick={() => {
                navigate("/custom");
              }}
              style={{
                borderBottom:
                  /^\/custom/i.test(pathname) === true &&
                  `5px solid var(--white-color)`,
              }}
            >
              <span>커스텀 샘플</span>
            </li>
          </ul>
          <form
            className={styles.header_wrap_search_wrap}
            onSubmit={onSubmitSearchInput}
          >
            <input
              type={"text"}
              value={searchInputValue}
              onChange={onChangeSearchInputValue}
              placeholder={"키워드로 음원, 작가 찾기"}
              required={true}
            />
            <button
              className={styles.search_wrap_search_button}
              type={"submit"}
            >
              <BiSearch size={25} color={"var(--main-color)"} />
            </button>
          </form>
        </div>
      </header>
    </div>
  );
};

export default Header;
