import React from "react";
import styles from "./NoticePage.module.css";
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useGetNoticeDetailQuery } from "../../store/services/noticeApi";
import IconNavigtaion from "../../components/IconNavigation/IconNavigtaion";

const NoticePage = () => {
  const { noticeId } = useParams();
  const navigate = useNavigate();

  const {
    data: getNoticeDetailData,
    error: getNoticeDetailError,
    isFetching: isFetchingGetNoticeDetail,
  } = useGetNoticeDetailQuery({ noticeId });
  return (
    <>
      <Header />
      <main className={styles.main_wrap}>
        <nav className={styles.back_nav}>
          <BiChevronLeft
            size={29}
            color={"var(--main-color)"}
            onClick={() => navigate("/notice")}
          />
          <p>공지사항으로 돌아가기</p>
        </nav>
        <div className={styles.title}>
          <span>
            {isFetchingGetNoticeDetail ? null : getNoticeDetailError ? (
              <>{getNoticeDetailError}</>
            ) : getNoticeDetailData ? (
              <>[공지] {getNoticeDetailData.title}</>
            ) : null}
          </span>
          <span className={styles.date}>
            {isFetchingGetNoticeDetail ? null : getNoticeDetailError ? (
              <>{getNoticeDetailError}</>
            ) : getNoticeDetailData ? (
              <>{getNoticeDetailData.created.replace(/T(.*)/, "")}</>
            ) : null}
          </span>
        </div>
        <div className={styles.content}>
          {isFetchingGetNoticeDetail ? null : getNoticeDetailError ? (
            <>{getNoticeDetailError}</>
          ) : getNoticeDetailData ? (
            <>
              {getNoticeDetailData.content.split(/\n/).map((v, i) => {
                if (v) {
                  return <p key={i}>{v}</p>;
                } else {
                  return <br key={i} />;
                }
              })}
              {/* <img
                src={`${process.env.REACT_APP_BASEURL}${getNoticeDetailData.file}`}
                alt={
                  getNoticeDetailData.file.split("/")[
                    getNoticeDetailData.file.split("/").length - 1
                  ]
                }
              /> */}
            </>
          ) : null}
        </div>
      </main>
      <Footer />
      <IconNavigtaion />
    </>
  );
};

export default NoticePage;
