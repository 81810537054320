import React, { useEffect } from "react";
import styles from "./NoticeListPage.module.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useGetAllNoticesQuery } from "../../store/services/noticeApi";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { setIndex } from "../../store/features/paginationSlice";
import IconNavigtaion from "../../components/IconNavigation/IconNavigtaion";

const NoticeListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paginationSlice = useSelector((state) => state.pagination);

  const {
    data: getAllNoticesData,
    isFetching: isFetchingGetAllNoticesData,
    error: getAllNoticesError,
  } = useGetAllNoticesQuery(
    { pageIndex: paginationSlice.index },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    dispatch(setIndex(0));
    return () => {
      dispatch(setIndex(0));
    };
  }, []);
  return (
    <>
      <Header />
      <main className={styles.main_wrap}>
        <hr />
        <h2>공지사항</h2>
        {isFetchingGetAllNoticesData ? null : getAllNoticesError ? (
          <>{getAllNoticesError}</>
        ) : getAllNoticesData ? (
          <>
            <ul>
              {getAllNoticesData.noticeList.map((v, i) => {
                return (
                  <li key={v.id}>
                    <span
                      className={styles.title}
                      onClick={() => navigate(`/notice/${v.id}`)}
                    >
                      [공지] {v.title}
                    </span>
                    <span className={styles.date}>
                      {v.updated.replace(/T(.*)/, "")}
                    </span>
                  </li>
                );
              })}
            </ul>
            <Pagination
              pages={getAllNoticesData.pages}
              style={{
                display: getAllNoticesData.pages ? "flex" : "none",
                marginTop: "121px",
              }}
            />
          </>
        ) : null}
      </main>
      <Footer />
      <IconNavigtaion />
    </>
  );
};

export default NoticeListPage;
