import React, { useEffect, useRef } from "react";
import styles from "./UseProvisionPage.module.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import IconNavigtaion from "../../components/IconNavigation/IconNavigtaion";
import { useGetProvisionQuery } from "../../store/services/provisionApi";

const UseProvisionPage = () => {
  const { data: getProvisionData, error: getProvisionError } =
    useGetProvisionQuery({ type: "service" });

  const mainRef = useRef(null);

  useEffect(() => {
    if (getProvisionError) {
      mainRef.current.innerHTML = getProvisionError;
    } else {
      mainRef.current.innerHTML = getProvisionData ?? null;
    }
  }, [getProvisionData, getProvisionError]);
  return (
    <>
      <Header />
      <main ref={mainRef} className={styles.main_wrap}></main>
      {/* <main ref={mainRef} className={styles.main_wrap}>
        <hr />
        <h1>이용약관</h1>
        <nav className={styles.main_wrap_nav}>
          <ul>
            <li>
              <a href={"#1"}>제1조 목적</a>
            </li>
            <li>
              <a href={"#2"}>제2조 약관의 효력 및 변경</a>
            </li>
            <li>
              <a href={"#3"}>제3조 용어의 정의</a>
            </li>
            <li>
              <a href={"#4"}>제4조 서비스의 종류</a>
            </li>
            <li>
              <a href={"#5"}>제5조 게시물 또는 내용물의 삭제</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href={"#6"}>제6조 게시물의 저작권</a>
            </li>
            <li>
              <a href={"#7"}>제7조 금지행위</a>
            </li>
            <li>
              <a href={"#8"}>제8조 약관 외 준칙 및 관련 법령과의 관계</a>
            </li>
            <li>
              <a href={"#9"}>제9조 면책 사항</a>
            </li>
            <li>
              <a href={"#10"}>제10조 관할 법원</a>
            </li>
          </ul>
        </nav>
        <h1>제 1 장 총칙</h1>
        <h2 id={"1"}>제1조 목적</h2>
        <p>
          본 약관은 주식회사 민트미디어(이하 ‘회사’라 합니다)가 운영하는 디지털
          콘텐츠 서비스인 보이스픽에서 제공하는 음성 및 기타 서비스(이하
          ‘서비스’라 합니다)의 이용과 관련하여 회사와 회원과의 권리, 의무 및
          책임사항 등을 규정함을 목적으로 합니다.
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"2"}
        >
          제2조 약관의 효력 및 변경
        </h2>
        <p>
          ① 본 약관은 서비스를 이용하고자 본 약관에 동의한 모든 회원에 대하여 그
          효력을 발생합니다.
          <br />② 본 약관의 내용은 보이스픽 인터넷 홈페이지 (http://voicepick.kr
          이하’홈페이지’라 합니다.)에 게재되고, 본 약관에 대하여 회원이
          동의함으로써 그 효력이 발생합니다.
          <br />③ 회사는 약관의 규제에 관한 법률 전자상거래 등에서의
          소비자보호에 관한 법률, 정보통신망 이용촉진 및 정보보호에 관한 법률,
          방문판매 등에 관한 법률 등 관련법령을 위반하지 않는 범위 내에서 필요
          시 약관을 변경할 수 있으며, 약관을 변경할 경우에는 적용일자 및
          변경사유를 명시하여 현행약관과 함께 서비스 초기화면에 그 적용일자 10일
          이전부터 적용일자 이후 상당한 기간 동안 공지합니다. 다만, 회원에게
          불리하게 변경되거나 중요한 내용인 경우에는 30일 이전부터 상기 방식으로
          공지하며 이러한 공지 외에 이메일 발송, 로그인 시 동의창 등 전자적
          수단을 통해 별도로 통지합니다.
          <br />④ 회사가 본 조 제3항에 따라 변경 약관을 공지 또는 통지하면서,
          회원이 약관 변경 적용일까지 거부의사를 표시하지 아니하는 경우, 약관의
          변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이
          명시적으로 변경된 약관의 효력 발생일까지 약관 변경에 대한 거부의사를
          표시하지 아니하면 회원이 변경 약관에 동의한 것으로 간주합니다. 회원은
          변경된 약관에 동의하지 않을 경우 본 서비스 이용을 중단하고 이용계약을
          해지할 수 있습니다.
          <br />⑤ 본 약관에 동의하는 것은 회사가 운영하는 본 서비스의 홈페이지를
          정기적으로 방문하여 약관의 변경사항을 확인하는 것에 동의함을
          의미합니다. 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원의
          피해에 대하여 회사는 책임이 없습니다.
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"3"}
        >
          제3조 용어의 정의
        </h2>
        <p>
          ① 본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
          <br />
          <span>1. 회원 : 본 약관에 동의한 개인이나 법인 고객</span>
          <br />
          <span>
            2. 상품 : 서비스의 판매 또는 전송을 위하여 등록한 프로그램 또는
            콘텐츠
          </span>
          <br />
          <span>
            3. 게시물 : 서비스 내에 회원이 올린 영상, 글, 사진, 그림, 각종
            파일과 링크, 각종 답글 등의 정보의 총칭
          </span>
          <br />② 본 조 제1항에서 정의된 용어 이외의 용어에 대해서는 관계 법령
          및 서비스 별 이용기준에서 정의하는 바에 따릅니다.
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"4"}
        >
          제4조 서비스의 종류
        </h2>
        <p>
          회사가 제공하는 서비스는 다음과 같습니다.
          <br />
          <span>1. 성우 음성, 게임 음성, 오디오북 등의 정보검색 서비스</span>
          <br />
          <span>2. 광고 및 프로모션 서비스</span>
          <br />
          <span>
            3. 기타서비스 : 상품에 대한 문의하기 등 기타 정보제공 서비스, 회사가
            직접 또는 제휴사와 공동으로 제공하는 서비스
          </span>
        </p>
        <h1
          style={{
            marginTop: "80px",
          }}
        >
          제 2장 서비스의 이용
        </h1>
        <h2 id={"5"}>제5조 게시물 또는 내용물의 삭제</h2>
        <p>
          ① 회사는 회원이 게시하거나 전달하는 모든 게시물이 다음 각 호의 경우에
          해당한다고 판단되는 경우 사전통지 없이 삭제할 수 있습니다.
          <br />
          <span>
            1. 회사, 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를
            손상시키는 내용인 경우
          </span>
          <br />
          <span>
            2. 공공질서 및 미풍양속에 위반되는 내용의 게시물 유포 등에 해당하는
            경우
          </span>
          <br />
          <span>3. 범죄 행위에 결부된다고 인정되는 내용인 경우</span>
          <br />
          <span>
            4. 회사 또는 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
          </span>
          <br />
          <span>
            5. 본 조 제2항 소정의 세부이용지침을 통하여 회사에서 규정한
            게시기간을 초과한 경우
          </span>
          <br />
          <span>
            6. 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우
          </span>
          <br />
          <span>
            7. 기타 관계 법령 및 회사의 지침 등에 위반된다고 판단되는 경우
          </span>
          <br />② 회사는 게시물에 관련된 세부이용지침을 별도로 정하여 시행할 수
          있으며, 회원은 그 지침에 따라 각종 게시물을 등록하거나 삭제하여야
          합니다.
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"6"}
        >
          제6조 게시물의 저작권
        </h2>
        <p>
          ① 회사는 회원이 서비스 내에 게시한 게시물(회원간 전달 포함)을 서비스
          내에 게시할 수 있는 권리를 갖습니다.
          <br />② 회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권,
          프로그램 저작권 등을 침해하더라도 회사에게 고의 또는 과실이 없는 한
          이에 대한 민, 형사상의 책임을 부담하지 않습니다.
          <br />③ 회사는 회원이 이용계약을 해지하거나 적법한 사유로 이용계약이
          해지된 경우 해당 회원이 게시하였던 게시물을 삭제할 수 있습니다. 또한
          회사는 회원의 게시물이 저작권을 침해하는 것으로 추정되는 경우에 이를
          본 약관이 정한 방법에 의하여 회원에게 통지하고 삭제할 수 있습니다.
          <br />④ 회사가 작성한 저작물에 대한 저작권은 회사에 귀속합니다.
          <br />⑤ 회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등
          서비스에 게재된 자료를 영리목적으로 이용하거나 제3자에게 이용하게 할
          수 없으며, 게시물에 대한 저작권 침해는 관계 법령의 적용을 받습니다.
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"7"}
        >
          제7조 금지행위
        </h2>
        <p>
          ① 회원이 본 서비스를 통하여 게시, 게재, 전자메일, 기타 제반 전송한
          모든 내용은 회원의 소유이며, 이에 대하여 회원이 책임을 지게 됩니다.
          <br />② 회원은 다음 각 호의 행위를 하여서는 아니 됩니다.
          <br />
          <span>
            1. 회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제
            또는 유통시키거나 상업적으로 이용하는 행위
          </span>
          <br />
          <span> 2. 타인의 명예를 손상시키거나 불이익을 주는 행위.</span>
          <br />
          <span>
            3. 음란물을 게재, 공개하거나 음란사이트를 연결(링크)하는 행위
          </span>
          <br />
          <span>
            4. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위.
          </span>
          <br />
          <span>
            5. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성
            등을 타인에게 유포하는 행위
          </span>
          <br />
          <span>
            6. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을
            유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
          </span>
          <br />
          <span>
            7. 서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수
            있는 정보 및 수신자의 의사에 반하여 광고성 정보를 전송하는 행위
          </span>
          <br />
          <span>
            8. 회사에서 공식적으로 인정한 경우를 제외한 서비스를 이용하여 상품을
            판매하는 영업 활동 및 해킹, 광고를 통한 수익, 음란사이트를 통한
            상업행위, 상용소프트웨어 불법배포 행위.
          </span>
          <br />
          <span>
            9. 법률에 의하거나 계약상 또는 위임에 의하여 전송할 수 있는 권리가
            없는 내용을 게시, 게재, 전자메일 또는 여하한의 방법으로 전송하거나
            공개하는 행위.
          </span>
          <br />
          <span>
            10. 타인을 "스톡(stalk)"하거나 달리 괴롭히는 행위 또는 다른 회원에
            대한 개인정보를 수집 또는 저장하는 행위
          </span>
          <br />
          <span> 11. 판매에 부적합한 상품을 등록하는 행위</span>
          <br />
          <span>
            12. 회사가 제공한 서비스 이용방법이 아닌 비정상적인 방법으로 본
            서비스를 이용하거나 시스템에 접근하는 행위
          </span>
          <br />
          <span>
            13. 허위 사실을 기재하거나 다른 회원의 ID 및 비밀번호, 카드정보,
            계좌정보 등을 도용하여 서비스 이용 신청하거나 변경하는 행위
          </span>
          <br />
          <span>
            14. 회사의 직원이나 운영자를 사칭하여 또는 타인의 명의를 도용하여
            글을 게시하거나 메일을 발송하는 행위
          </span>
          <br />
          <span>
            15. 타인과의 관계를 허위로 명시하거나 타인으로 가장하는 행위
          </span>
          <br />
          <span>
            16. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할
            목적으로 허위의 정보를 유통시키는 행위
          </span>
          <br />
          <span>
            17. 상품의 구입과 철회를 반복하여 서비스 운영에 지장을 주는 행위
          </span>
          <br />
          <span>
            18. 본 서비스의 해지 및 재가입을 반복적으로 행함으로써 회사가
            제공하는 할인쿠폰, 이벤트 혜택 등 경제상의 이익을 불 • 편법으로
            수취하는 행위
          </span>
          <br />
          <span>
            19. 콘텐츠의 DRM(Digital Rights Management)을 무력화하는 일체의 행위
          </span>
          <br />
          <span>
            20. 동일한 아이디로 5대 이상의 모바일 단말기에서 전자책을 이용하는
            경우
          </span>
          <br />
          <span>
            21. 자신의 아이디 및 전차책, 이용권, 쿠폰, 캐시 등 서비스 요소의
            전부 또는 일부를 타인에게 판매, 대여, 양도하는 행위 및 이러한 행위를
            광고하는 행위
          </span>
          <br />
          ③ 회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한
          주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의
          업무에 방해되는 행위를 하여서는 아니 됩니다.
          <br />④ 회원의 본 조 위반이 있을 경우 회사는 회원의 계정을 중지하거나
          해지할 수 있으며, 현재 또는 장래의 모든 또는 일부의 서비스 이용을
          거절할 수 있습니다. 또한 본 조의 위반으로 인해 발생된 회원의
          관계기관에 의한 구속 등 법적 조치 등에 관해서는 회사가 책임을 지지
          않으며, 회원은 이와 같은 행위와 관련하여 회사에 손해를 끼쳤을 경우
          회사에 대하여 손해배상 의무를 집니다.
        </p>
        <h1
          style={{
            marginTop: "80px",
          }}
        >
          제 3장 기타
        </h1>
        <h2 id={"8"}>제8조 약관 외 준칙 및 관련 법령과의 관계</h2>
        <p>
          본 약관에 명시되지 않은 사항에 대해서는 전기통신사업법 및
          전자거래기본법, 콘텐츠산업진흥법, 전자상거래 등에서의 소비자보호에
          관한 법률, 저작권법 등 관계법령 및 회사가 정한 서비스의 세부이용지침
          등의 규정에 의합니다.
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"9"}
        >
          제9조 면책 사항
        </h2>
        <p>
          ① 회사는 다음 각 호에 해당하는 사유로 인하여 회원 또는 제3자에게
          발생한 손해에 대하여는 그 책임을 지지 아니합니다.
          <br />
          <span>
            1. 천재지변 또는 이에 준하는 불가항력으로 인해 서비스를 제공할 수
            없는 경우
          </span>
          <br />
          <span>
            2. 회사의 관리 영역이 아닌 공중통신선로의 장애로 서비스 이용이
            불가능한 경우
          </span>
          <br />
          <span>
            3. 기타 회사의 귀책 사유가 없는 통신서비스 등의 장애로 인한 경우
          </span>
          <br />
          <span>
            4. 회원이 본 서비스를 이용하여 기대하는 이익을 상실하거나, 그 밖에
            본 서비스를 통하여 얻은 자료로 인한 손해를 입은 경우
          </span>
          <br />
          <span>
            5. 회원 상호간 또는 회원과 제3자 상호간에 본 서비스를 매개로 발생한
            분쟁의 경우
          </span>
          <br />
          <span>
            6. 회원이 제공하는 상품 또는 정보 등을 통해 표현하거나 드러나는
            회원의 견해에 따른 경우
          </span>
          <br />
          <span>
            7. 본 서비스 화면에서 링크, 배너 등을 통하여 연결된 회사와 회원간에
            이루어진 거래의 경우
          </span>
          <br />② 회사는 회원이 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의
          내용에 관하여 회사에게 고의 또는 과실이 없는 한 책임을 지지 않습니다.
        </p>
        <h2
          style={{
            marginTop: "50px",
          }}
          id={"10"}
        >
          제10조 관할 법원
        </h2>
        <p>
          ① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와
          회원은 분쟁의 해결을 위해 성실히 협의합니다.
          <br />② 본 조 제1항의 협의에서도 분쟁이 해결되지 않아 소송이 제기되는
          경우에는 민사소송법에 따라 관할법원을 정합니다.
        </p>
      </main> */}
      <IconNavigtaion />
      <Footer />
    </>
  );
};

export default UseProvisionPage;
