import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const searchApi = createApi({
  reducerPath: "searchApi",
  tagTypes: ["search"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
  }),
  endpoints: (builder) => ({
    getSearchResult: builder.query({
      query: ({ word, pageIndex }) => ({
        url: `/search/?word=${word}&page=${pageIndex}`,
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        for (let i = 0; i < response.audioData.voiceList.length; i++) {
          response.audioData.voiceList[i].isPlay = false;
        }
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
      providesTags: ["search"],
    }),
  }),
});

export const { useGetSearchResultQuery, useLazyGetSearchResultQuery } =
  searchApi;
