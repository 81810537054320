import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const actorApi = createApi({
  reducerPath: "actorApi",
  tagTypes: ["actor"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
    prepareHeaders: (headers, { getState }) => {
      // console.log(getState(), headers);
      // headers.set(`Authorization`, `Bearer 1234`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllActors: builder.query({
      query: ({ pageIndex }) => ({
        url: `/actor/?page=${pageIndex}`,
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
      providesTags: ["actor"],
    }),

    getRandomActors: builder.query({
      query: () => ({
        url: `/actor/random/`,
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        return response.actorRandom;
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
      providesTags: ["actor"],
    }),

    postTagLists: builder.mutation({
      query: ({ filterList, pageIndex }) => ({
        url: `/filter/actor/?page=${pageIndex}`,
        method: "POST",
        body: {
          tagFind: filterList,
        },
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
      // invalidatesTags: ["actor"],
    }),

    getActor: builder.query({
      query: ({ id, pageIndex }) => ({
        url: `/actor/info/?id=${id}&page=${pageIndex}`,
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        const voiceList = [];
        for (let i = 0; i < response.voiceData.length; i++) {
          voiceList.push({ ...response.voiceData[i], isPlay: false });
        }
        return {
          actorData: response.actorData,
          voiceData: { voiceList, pages: response.pages },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
    }),
  }),
});

export const {
  useLazyGetAllActorsQuery,
  useGetRandomActorsQuery,
  usePostTagListsMutation,
  useGetActorQuery,
} = actorApi;
