import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const noticeApi = createApi({
  reducerPath: "noticeApi",
  tagTypes: ["notice"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
  }),
  endpoints: (builder) => ({
    getAllNotices: builder.query({
      query: ({ pageIndex }) => ({
        url: `/notice/?pages=${pageIndex}`,
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
      providesTags: ["notice"],
    }),

    getNoticeDetail: builder.query({
      query: ({ noticeId }) => ({
        url: `/notice/info/?id=${noticeId}`,
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        return response.noticeData;
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
      providesTags: ["notice"],
    }),
  }),
});

export const { useGetAllNoticesQuery, useGetNoticeDetailQuery } = noticeApi;
