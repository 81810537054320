import React from "react";
import styles from "./Footer.module.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer>
      <address className={styles.footer_wrap}>
        <ul className={styles.footer_wrap_bold_list}>
          <li onClick={() => navigate("/notice")}>공지사항</li>
          {/* <li>고객센터</li> */}
          <li onClick={() => navigate("/useprovision")}>이용약관</li>
          <li onClick={() => navigate("/personalinfoprovision")}>
            개인정보처리방침
          </li>
        </ul>
        <ul className={styles.footer_wrap_normal_list}>
          <li>
            <a
              href={"https://mintmedia.co.kr/"}
              target={"_blank"}
              rel="noreferrer"
            >
              민트미디어그룹
            </a>
          </li>
          <li>사업자등록번호 : 619-87-004-50</li>
          <li>주소 : 서울시 서초구 양재동 2-12 동일빌딩 5층</li>
          <li>
            <a href="tel:02-579-1640">고객센터 : 02-579-1640</a>
          </li>
        </ul>
        <p className={styles.footer_wrap_copyright}>
          © 2009 MINTMEDIAGROUP. All Rights Reserved.
        </p>
      </address>
    </footer>
  );
};

export default Footer;
