import React, { useState, useEffect } from "react";
import styles from "./VoiceListPage.module.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import IconNavigation from "../../components/IconNavigation/IconNavigtaion";
import Pagination from "../../components/Pagination/Pagination";
import { IoMdClose, IoMdRefresh } from "react-icons/io";
import VoiceList from "../../components/VoiceList/VoiceList";
import {
  useLazyGetAllVoicesQuery,
  usePostTagListsMutation,
} from "../../store/services/voiceApi";
import { setIndex } from "../../store/features/paginationSlice";
import { useSelector, useDispatch } from "react-redux";
import FilterList from "../../components/FilterList/FilterList";

const VoiceListPage = () => {
  const paginationSlice = useSelector((state) => state.pagination);
  const voiceCategorySlice = useSelector((state) => state.voiceCategory);
  const dispatch = useDispatch();

  const [
    postTagLists,
    {
      data: postTagListsData,
      error: postTagListsError,
      isLoading: isLoadingPostTagLists,
    },
  ] = usePostTagListsMutation();
  const [
    lazyGetAllVoices,
    {
      data: lazyGetAllVoicesData,
      error: lazyGetAllVoicesError,
      isFetching: isFetchingLazyGetAllVoices,
    },
  ] = useLazyGetAllVoicesQuery();

  const [selectedFilterList, setSelectedFilterList] = useState(new Set());
  const [allVoiceData, setAllVoiceData] = useState(null); // RTK Query의 transformResponse의 값을 저장

  const onClickRemoveIcon = (target) => {
    setSelectedFilterList((currentState) => {
      const set = new Set([...currentState]);
      set.delete(target);
      return set;
    });
  };
  const onClickResetIcon = () => {
    if (selectedFilterList.size === 0) return; //불필요한 리렌더링 방지
    setSelectedFilterList((v) => new Set());
  };

  // 트리거플로우 - 카테고리 변경 -> 필터리스트 변경 -> 페이지네이션 초기화 -> query호출
  useEffect(() => {
    dispatch(setIndex(0)); // 필터리스트가 바뀌면 페이지네이션도 초기값(0)으로 변경
  }, [selectedFilterList]);
  useEffect(() => {
    setSelectedFilterList((v) => new Set());
  }, [voiceCategorySlice]); // 카테고리 변경 시 필터리스트 초기화
  useEffect(() => {
    (async () => {
      try {
        if (selectedFilterList.size === 0) {
          //필터리스트가 없으면 useLazyGetAllVoicesQuery를 호출
          await lazyGetAllVoices({
            pageIndex: paginationSlice.index,
            category: voiceCategorySlice.category,
          });
        } else {
          //필터리스트가 있으면 usePostTagListsMutation을 호출
          await postTagLists({
            filterList: [...selectedFilterList],
            pageIndex: paginationSlice.index,
            category: voiceCategorySlice.category,
          });
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [paginationSlice, selectedFilterList]); //페이지네이션이 바뀔때 실행
  useEffect(() => {
    return () => {
      dispatch(setIndex(0));
    };
  }, []);
  useEffect(() => {
    setAllVoiceData(postTagListsData);
  }, [postTagListsData, isLoadingPostTagLists]);
  useEffect(() => {
    setAllVoiceData(lazyGetAllVoicesData);
  }, [lazyGetAllVoicesData, isFetchingLazyGetAllVoices]);
  return (
    <>
      <Header />
      <main>
        <article className={styles.article}>
          {/* 좌측 필터 바 시작 */}
          <FilterList
            fileterType={voiceCategorySlice.category}
            selectedFilterList={selectedFilterList}
            setSelectedFilterList={setSelectedFilterList}
          />
          {/* 좌측 필터 바 끝 */}
          {/* 우측 시작 */}
          <section className={styles.section}>
            <ul className={styles.select_filter}>
              {[...selectedFilterList].map((v, i) => {
                return (
                  <li key={v}>
                    {v}
                    <IoMdClose
                      color="white"
                      size={18}
                      onClick={() => onClickRemoveIcon(v)}
                    />
                  </li>
                );
              })}
              <li>
                초기화
                <IoMdRefresh
                  color="white"
                  size={18}
                  onClick={onClickResetIcon}
                />
              </li>
            </ul>
            {isFetchingLazyGetAllVoices ||
            isLoadingPostTagLists ? null : lazyGetAllVoicesError ||
              postTagListsError ? (
              <>{lazyGetAllVoicesError ?? postTagListsError}</>
            ) : allVoiceData ? (
              <>
                <ul className={styles.voice_list}>
                  {allVoiceData.voiceList.map((v, i) => {
                    return (
                      <li key={v.id}>
                        <VoiceList
                          voiceData={v}
                          index={i}
                          parentStateSetter={setAllVoiceData}
                        />
                      </li>
                    );
                  })}
                </ul>
                <Pagination
                  pages={allVoiceData.pages}
                  style={{
                    display: allVoiceData.pages ? "flex" : "none",
                    marginTop: "121px",
                  }}
                />
              </>
            ) : null}
          </section>
          {/* 우측 끝 */}
        </article>
      </main>
      <Footer />
      <IconNavigation />
    </>
  );
};

export default VoiceListPage;
