import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import pagination from "./features/paginationSlice";
import { actorApi } from "./services/actorApi";
import { noticeApi } from "./services/noticeApi";
import { customApi } from "./services/customApi";
import { voiceApi } from "./services/voiceApi";
import { tagListApi } from "./services/tagListApi";
import { provisionApi } from "./services/provisionApi";
import { searchApi } from "./services/searchApi";
import voiceCategory from "./features/voiceCategorySlice";
import { popupApi } from "./services/popupApi";

export const store = configureStore({
  reducer: {
    pagination, // 얘는 공식문서대로 paginationSlice.reducer로 export했음
    voiceCategory,
    [actorApi.reducerPath]: actorApi.reducer,
    [noticeApi.reducerPath]: noticeApi.reducer,
    [customApi.reducerPath]: customApi.reducer,
    [voiceApi.reducerPath]: voiceApi.reducer,
    [tagListApi.reducerPath]: tagListApi.reducer,
    [provisionApi.reducerPath]: provisionApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [popupApi.reducerPath]: popupApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      actorApi.middleware,
      noticeApi.middleware,
      customApi.middleware,
      voiceApi.middleware,
      tagListApi.middleware,
      provisionApi.middleware,
      searchApi.middleware,
      popupApi.middleware
    ),
});
setupListeners(store.dispatch);
