import React, { useState } from "react";
import styles from "./FilterList.module.css";
import { HiChevronDown } from "react-icons/hi";
import { GiCheckMark } from "react-icons/gi";
import { useGetAllTagListsQuery } from "../../store/services/tagListApi";

const FilterList = ({
  selectedFilterList,
  setSelectedFilterList,
  fileterType,
}) => {
  const {
    data: getAllTagListsData,
    error: getAllTagListsError,
    isFetching: isFetchingGetAllTagLists,
  } = useGetAllTagListsQuery({ type: fileterType });

  const [hiddenFilterList, setHiddenFilterList] = useState(new Set());

  const onClickFilterCheckbox = (v) => {
    if (selectedFilterList.has(v)) {
      setSelectedFilterList((currentState) => {
        const set = new Set([...currentState]);
        set.delete(v);
        return set;
      });
    } else {
      setSelectedFilterList((currentState) => {
        const set = new Set([...currentState]);
        set.add(v);
        return set;
      });
    }
  };
  const onClickSelectAll = () => {
    if (getAllTagListsData) {
      const set = new Set();
      let dataLength = 0;
      for (let i = 0; i < getAllTagListsData.tagDataKeys.length; i++) {
        for (
          let j = 0;
          j <
          getAllTagListsData.tagDataObj[getAllTagListsData.tagDataKeys[i]]
            .length;
          j++
        ) {
          dataLength++;
          set.add(
            getAllTagListsData.tagDataObj[getAllTagListsData.tagDataKeys[i]][j]
          );
        }
      }
      if (selectedFilterList.size === dataLength) return; //필요없는 리렌더링 방지
      setSelectedFilterList((v) => new Set([...set]));
    }
  };

  const onClickToggleIcon = (key) => {
    if (hiddenFilterList.has(key)) {
      setHiddenFilterList((currentState) => {
        const set = new Set([...currentState]);
        set.delete(key);
        return set;
      });
    } else {
      setHiddenFilterList((currentState) => {
        const set = new Set([...currentState]);
        set.add(key);
        return set;
      });
    }
  };
  return (
    <>
      <section className={styles.filter}>
        {isFetchingGetAllTagLists ? null : getAllTagListsError ? (
          <>{getAllTagListsError}</>
        ) : getAllTagListsData ? (
          <>
            <div className={styles.filter_title_div}>
              <h2>필터</h2>
              <p onClick={onClickSelectAll}>모두선택</p>
            </div>
            <hr />
            {getAllTagListsData.tagDataKeys.map((key) => {
              return (
                <div key={key} className={styles.filter_section}>
                  <div className={styles.filter_section_title_div}>
                    <h2>{key}</h2>
                    <HiChevronDown
                      size={25}
                      color={"#707070"}
                      onClick={() => onClickToggleIcon(key)}
                      style={{
                        transform: `rotate(${
                          hiddenFilterList.has(key) === false
                            ? "0deg"
                            : "180deg"
                        })`,
                        transition: ".1s",
                      }}
                    />
                  </div>
                  {hiddenFilterList.has(key) === false
                    ? getAllTagListsData.tagDataObj[key].map((v) => {
                        return (
                          <ul key={v}>
                            <li>
                              <div
                                className={styles.checkbox_wrap}
                                onClick={() => onClickFilterCheckbox(v)}
                              >
                                <GiCheckMark
                                  size={16}
                                  color={"var(--main-color)"}
                                  style={{
                                    display: selectedFilterList.has(v)
                                      ? "block"
                                      : "none",
                                  }}
                                />
                              </div>
                              <span>{v}</span>
                            </li>
                          </ul>
                        );
                      })
                    : null}
                </div>
              );
            })}
          </>
        ) : null}
        {/* <div className={styles.filter_section}>
          <div className={styles.filter_section_title_div}>
            <h2>성별</h2>
            <HiChevronDown size={25} color={"#707070"} />
          </div>
          <ul>
            <li>
              <div className={styles.checkbox_wrap}>
                <GiCheckMark size={16} color={"var(--main-color)"} />
              </div>
              <span>여성</span>
            </li>
            <li>
              <div className={styles.checkbox_wrap}>
                <GiCheckMark size={16} color={"var(--main-color)"} />
              </div>
              <span>남성</span>
            </li>
          </ul>
        </div> */}
      </section>
    </>
  );
};

export default FilterList;
