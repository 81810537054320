import React, { useEffect, useState } from "react";
import styles from "./MainPage.module.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import mainImage from "../../assets/images/그룹 3820.png";
import IconNavigtaion from "../../components/IconNavigation/IconNavigtaion";
import { BiChevronRight } from "react-icons/bi";
import VoiceList from "../../components/VoiceList/VoiceList";
import { useNavigate } from "react-router-dom";
import { useGetRandomActorsQuery } from "../../store/services/actorApi";
import { useGetRandomVoicesQuery } from "../../store/services/voiceApi";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Autoplay } from "swiper/modules";
import "swiper/css";
import ActorList from "../../components/ActorList/ActorList";
import Popup from "../../components/Popup/Popup";

const Main = () => {
  const navigate = useNavigate();

  const {
    data: getRandomActorsData,
    error: getRandomActorsError,
    isLoading: isLoadingGetRandomActors,
  } = useGetRandomActorsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: getRandomVoicesData,
    error: getRandomVoicesError,
    isLoading: isLoadingGetRandomVoices,
  } = useGetRandomVoicesQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [randomVoiceData, setRandomVoiceData] = useState(null);

  useEffect(() => {
    if (getRandomVoicesData !== undefined) {
      setRandomVoiceData(getRandomVoicesData);
    }
  }, [getRandomVoicesData]);
  return (
    <>
      <Header />
      <main>
        <header className={styles.main_header}>
          <div className={styles.main_header_wrap}>
            <div className={styles.main_header_wrap_main_text_wrap}>
              <h1 className={styles.main_text_wrap_main_text}>
                보이스픽에서 다양한
                <br />
                성우의 음성을 <strong>Pick!</strong> 해보세요.
              </h1>
              <button
                type={"button"}
                className={styles.main_text_wrap_button}
                onClick={() => navigate("/actor")}
              >
                성우리스트 바로가기
              </button>
            </div>
            <img
              className={styles.main_header_wrap_main_logo}
              src={mainImage}
              alt={"그룹3820"}
              height={425}
            />
          </div>
        </header>
        <article className={styles.main_article}>
          <section className={styles.main_article_section}>
            <hr />
            <div className={styles.section_title_wrap}>
              <h2>보이스픽 성우</h2>
              <div
                className={styles.section_title_wrap_showmore}
                onClick={() => {
                  navigate("/actor");
                }}
              >
                <p>더보기</p>
                <BiChevronRight color={"var(--main-color)"} size={25} />
              </div>
            </div>
            <div className={styles.swiper_wrap}>
              {isLoadingGetRandomActors ? null : getRandomActorsError ? (
                <>{getRandomActorsError}</>
              ) : getRandomActorsData ? (
                <Swiper
                  slidesPerView={4}
                  loop={true}
                  spaceBetween={13}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  mousewheel={true}
                  modules={[Mousewheel, Autoplay]}
                >
                  {getRandomActorsData.map((v, i) => {
                    return (
                      <SwiperSlide key={v.id} className={styles.swiper_slide}>
                        <ActorList actorData={v} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : null}
            </div>
          </section>
          <section className={styles.main_article_section}>
            <hr />
            <div className={styles.section_title_wrap}>
              <h2>보이스픽 샘플</h2>
              <div
                className={styles.section_title_wrap_showmore}
                onClick={() => navigate("/voice")}
              >
                <p>더보기</p>
                <BiChevronRight color={"var(--main-color)"} size={25} />
              </div>
            </div>
            <ul className={styles.voicesample_ul}>
              {isLoadingGetRandomVoices ? null : getRandomVoicesError ? (
                <>{getRandomVoicesError}</>
              ) : randomVoiceData ? (
                <>
                  {randomVoiceData.voiceList.map((v, i) => {
                    return (
                      <VoiceList
                        key={v.id}
                        voiceData={v}
                        index={i}
                        parentStateSetter={setRandomVoiceData}
                      />
                    );
                  })}
                </>
              ) : null}
            </ul>
          </section>
        </article>
      </main>
      <Footer />
      <IconNavigtaion />
      <Popup />
    </>
  );
};

export default Main;
