import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/store";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import "./assets/css/reset.css";
import "./assets/css/style.css";
import { scrollTopWhenPageBeforeMount } from "./utils/scroll";
import MainPage from "./pages/MainPage/MainPage";
import UseProvisionPage from "./pages/UseProvisionPage/UseProvisionPage";
import PersonalInfoProvisionPage from "./pages/PersonalInfoProvisionPage/PersonalInfoProvisionPage";
import NoticeListPage from "./pages/NoticeListPage/NoticeListPage";
import NoticePage from "./pages/NoticePage/NoticePage";
import CustomPage from "./pages/CustomPage/CustomPage";
import VoiceListPage from "./pages/VoiceListPage/VoiceListPage";
import ActorListPage from "./pages/ActorListPage/ActorListPage";
import ActorPage from "./pages/ActorPage/ActorPage";
import SearchResultPage from "./pages/SearchResultPage/SearchResultPage";

const router = createBrowserRouter([
  {
    path: "*",
    loader: () => {
      return redirect("/");
    },
  },
  {
    path: "/",
    element: <MainPage />,
    loader: () => {
      scrollTopWhenPageBeforeMount();
      return null;
    },
  },
  {
    path: "/useprovision",
    element: <UseProvisionPage />,
    loader: () => {
      scrollTopWhenPageBeforeMount();
      return null;
    },
  },
  {
    path: "/personalinfoprovision",
    element: <PersonalInfoProvisionPage />,
    loader: () => {
      scrollTopWhenPageBeforeMount();
      return null;
    },
  },
  {
    path: "/notice",
    element: <NoticeListPage />,
    loader: () => {
      scrollTopWhenPageBeforeMount();
      return null;
    },
  },
  {
    path: "/notice/:noticeId",
    element: <NoticePage />,
    loader: () => {
      scrollTopWhenPageBeforeMount();
      return null;
    },
  },
  {
    path: "/custom",
    element: <CustomPage />,
    loader: () => {
      scrollTopWhenPageBeforeMount();
      return null;
    },
  },
  {
    path: "/voice",
    element: <VoiceListPage />,
    loader: () => {
      scrollTopWhenPageBeforeMount();
      return null;
    },
  },
  {
    path: "/actor",
    element: <ActorListPage />,
    loader: () => {
      scrollTopWhenPageBeforeMount();
      return null;
    },
  },
  {
    path: "/actor/:id",
    element: <ActorPage />,
    loader: () => {
      scrollTopWhenPageBeforeMount();
      return null;
    },
  },
  {
    path: "/search/:word",
    element: <SearchResultPage />,
    loader: () => {
      scrollTopWhenPageBeforeMount();
      return null;
    },
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
