import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import styles from "./SearchResultPage.module.css";
import { useParams } from "react-router-dom";
import { useGetSearchResultQuery } from "../../store/services/searchApi";
import { useDispatch, useSelector } from "react-redux";
import IconNavigtaion from "../../components/IconNavigation/IconNavigtaion";
import Pagination from "../../components/Pagination/Pagination";
import ActorList from "../../components/ActorList/ActorList";
import { setIndex } from "../../store/features/paginationSlice";
import VoiceList from "../../components/VoiceList/VoiceList";

const SearchResultPage = () => {
  const { word } = useParams();

  const paginationSlice = useSelector((state) => state.pagination);
  const dispatch = useDispatch();

  const {
    data: getSearchResultData,
    error: getSearchResultError,
    isFetching: isFetchingGetSearchResult,
  } = useGetSearchResultQuery(
    { word, pageIndex: paginationSlice.index },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [category, setCategory] = useState("actor");
  const [allVoiceData, setAllVoiceData] = useState(null);

  useEffect(() => {
    return () => {
      dispatch(setIndex(0));
    };
  }, []);

  useEffect(() => {
    dispatch(setIndex(0));
  }, [category]);

  useEffect(() => {
    if (getSearchResultData) {
      switch (category) {
        case "game":
          setAllVoiceData(getSearchResultData.gameData);
          break;
        case "audio":
          setAllVoiceData(getSearchResultData.audioData);
          break;
        default:
          break;
      }
    }
  }, [getSearchResultData, category]);

  return (
    <>
      <Header />
      <main className={styles.main}>
        <h1 className={styles.result_word}>'{word}' 검색결과</h1>
        <p className={styles.result_count}>
          총{" "}
          <span>
            {getSearchResultData
              ? getSearchResultData.gameData.total +
                getSearchResultData.audioData.total +
                getSearchResultData.actorData.total
              : 0}
          </span>
          개의 검색 결과
        </p>
        <ul className={styles.result_category}>
          <li>
            <span
              style={{
                color: category === "actor" && "#009398",
              }}
              onClick={() => setCategory("actor")}
            >
              성우(
              {getSearchResultData ? getSearchResultData.actorData.total : 0})
            </span>
          </li>
          <li>
            <span
              style={{
                color: category === "charactor" && "#009398",
              }}
              onClick={() => setCategory("charactor")}
            >
              캐릭터(
              {getSearchResultData ? getSearchResultData.gameData.total : 0})
            </span>
          </li>
          <hr />
          <li>
            <span
              style={{
                color: category === "narration" && "#009398",
              }}
              onClick={() => setCategory("narration")}
            >
              나레이션(
              {getSearchResultData ? getSearchResultData.audioData.total : 0})
            </span>
          </li>
          <hr />
        </ul>
        {isFetchingGetSearchResult ? null : getSearchResultError ? (
          <>{getSearchResultError}</>
        ) : getSearchResultData ? (
          category === "charactor" ? (
            getSearchResultData.gameData.voiceList.length !== 0 ? (
              <>
                <ul className={styles.voice_list}>
                  {allVoiceData
                    ? allVoiceData.voiceList.map((v, i) => {
                        return (
                          <li key={v.id}>
                            <VoiceList
                              voiceData={v}
                              index={i}
                              parentStateSetter={setAllVoiceData}
                            />
                          </li>
                        );
                      })
                    : null}
                </ul>
                <Pagination
                  pages={getSearchResultData.gameData.pages}
                  style={{
                    display: getSearchResultData.gameData.pages
                      ? "flex"
                      : "none",
                    margin: "121px 0 190px",
                  }}
                />
              </>
            ) : (
              <article className={styles.result_none}>
                <h1>검색 결과가 없습니다.</h1>
              </article>
            )
          ) : category === "narration" ? (
            getSearchResultData.audioData.voiceList.length !== 0 ? (
              <>
                <ul className={styles.voice_list}>
                  {allVoiceData
                    ? allVoiceData.voiceList.map((v, i) => {
                        return (
                          <li key={v.id}>
                            <VoiceList
                              voiceData={v}
                              index={i}
                              parentStateSetter={setAllVoiceData}
                            />
                          </li>
                        );
                      })
                    : null}
                </ul>
                <Pagination
                  pages={getSearchResultData.audioData.pages}
                  style={{
                    display: getSearchResultData.audioData.pages
                      ? "flex"
                      : "none",
                    margin: "121px 0 190px",
                  }}
                />
              </>
            ) : (
              <article className={styles.result_none}>
                <h1>검색 결과가 없습니다.</h1>
              </article>
            )
          ) : category === "actor" ? (
            getSearchResultData.actorData.actorList.length !== 0 ? (
              <>
                <ul className={styles.actor_list}>
                  {getSearchResultData.actorData.actorList.map((v, i) => {
                    return (
                      <li key={v.id}>
                        <ActorList actorData={v} />
                      </li>
                    );
                  })}
                </ul>
                <Pagination
                  pages={getSearchResultData.actorData.pages}
                  style={{
                    display: getSearchResultData.actorData.pages
                      ? "flex"
                      : "none",
                    margin: "121px 0 190px",
                  }}
                />
              </>
            ) : (
              <article className={styles.result_none}>
                <h1>검색 결과가 없습니다.</h1>
              </article>
            )
          ) : null
        ) : null}
        {/* 검색결과 없을 때 UI */}
        {/* <article className={styles.result_none}>
          <h1>검색 결과가 없습니다.</h1>
        </article> */}
      </main>
      <Footer />
      <IconNavigtaion />
    </>
  );
};

export default SearchResultPage;
