import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const provisionApi = createApi({
  reducerPath: "provisionApi",
  tagTypes: ["provision"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
  }),
  endpoints: (builder) => ({
    getProvision: builder.query({
      query: ({ type }) => ({
        url: `/rule/?type=${type}`,
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        return response.html;
      },
      transformErrorResponse: (response, meta, arg) => {
        // console.log(response);
        return response.status;
      },
      providesTags: ["provision"],
    }),
  }),
});

export const { useGetProvisionQuery } = provisionApi;
