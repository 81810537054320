import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  index: 0,
};

export const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    incrementIndex: (state) => {
      state.index += 1;
    },
    decrementIndex: (state) => {
      state.index -= 1;
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    },
  },
});

export const { incrementIndex, decrementIndex, setIndex } =
  paginationSlice.actions;

export default paginationSlice.reducer;
