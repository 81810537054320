import React, { useState, useEffect } from "react";
import styles from "./ActorListPage.module.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import IconNavigation from "../../components/IconNavigation/IconNavigtaion";
import Pagination from "../../components/Pagination/Pagination";
import { IoMdClose, IoMdRefresh } from "react-icons/io";
import { setIndex } from "../../store/features/paginationSlice";
import { useSelector, useDispatch } from "react-redux";
import FilterList from "../../components/FilterList/FilterList";
import {
  useLazyGetAllActorsQuery,
  usePostTagListsMutation,
} from "../../store/services/actorApi";
import ActorList from "../../components/ActorList/ActorList";

const VoiceListPage = () => {
  const paginationSlice = useSelector((state) => state.pagination);
  const dispatch = useDispatch();

  const [
    postTagLists,
    {
      data: postTagListsData,
      error: postTagListsError,
      isLoading: isLoadingPostTagLists,
    },
  ] = usePostTagListsMutation();
  const [
    lazyGetAllActors,
    {
      data: lazyGetAllActorsData,
      error: lazyGetAllActorsError,
      isFetching: isFetchingGetAllActors,
    },
  ] = useLazyGetAllActorsQuery();

  const [selectedFilterList, setSelectedFilterList] = useState(new Set()); // 필터리스트 선택항목

  const onClickRemoveIcon = (target) => {
    setSelectedFilterList((currentState) => {
      const set = new Set([...currentState]);
      set.delete(target);
      return set;
    });
  };
  const onClickResetIcon = () => {
    if (selectedFilterList.size === 0) return; // 불필요한 리렌더링 방지
    setSelectedFilterList((v) => new Set());
  };

  // 트리거 플로우 - 필터리스트 변경 -> 페이지네이션 초기화 -> query호출
  useEffect(() => {
    dispatch(setIndex(0)); // 필터리스트가 바뀌면 페이지네이션도 초기값(0)으로 변경
  }, [selectedFilterList]);
  useEffect(() => {
    (async () => {
      try {
        if (selectedFilterList.size === 0) {
          // 필터리스트가 없으면 useLazyGetAllActorsQuery를 호출
          await lazyGetAllActors({ pageIndex: paginationSlice.index });
        } else {
          // 필터리스트가 있으면 usePostTagListsMutation을 호출
          await postTagLists({
            filterList: [...selectedFilterList],
            pageIndex: paginationSlice.index,
          });
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [paginationSlice, selectedFilterList]); // 페이지네이션이 바뀔때 실행
  useEffect(() => {
    return () => {
      dispatch(setIndex(0));
    };
  }, []);
  return (
    <>
      <Header />
      <main>
        <article className={styles.article}>
          {/* 좌측 필터 바 시작 */}
          <FilterList
            fileterType={"actor"}
            selectedFilterList={selectedFilterList}
            setSelectedFilterList={setSelectedFilterList}
          />
          {/* 좌측 필터 바 끝 */}
          {/* 우측 시작 */}
          <section className={styles.section}>
            <ul className={styles.select_filter}>
              {[...selectedFilterList].map((v, i) => {
                return (
                  <li key={v}>
                    {v}
                    <IoMdClose
                      color="white"
                      size={18}
                      onClick={() => onClickRemoveIcon(v)}
                    />
                  </li>
                );
              })}
              <li
              // style={{
              //   display: selectedFilterList.size === 0 ? "none" : "flex",
              // }}
              >
                초기화
                <IoMdRefresh
                  color="white"
                  size={18}
                  onClick={onClickResetIcon}
                />
              </li>
            </ul>
            {isFetchingGetAllActors ? null : lazyGetAllActorsError ? (
              <>{lazyGetAllActorsError}</>
            ) : lazyGetAllActorsData ? (
              selectedFilterList.size === 0 ? (
                <>
                  <ul className={styles.actor_list}>
                    {lazyGetAllActorsData.actorList.map((v, i) => {
                      return (
                        <li key={v.id}>
                          <ActorList actorData={v} />
                        </li>
                      );
                    })}
                  </ul>
                  <Pagination
                    pages={lazyGetAllActorsData.pages}
                    style={{
                      display: lazyGetAllActorsData.pages ? "flex" : "none",
                      marginTop: "121px",
                    }}
                  />
                </>
              ) : isLoadingPostTagLists ? null : postTagListsError ? (
                <>{postTagListsError}</>
              ) : postTagListsData ? (
                <>
                  <ul className={styles.actor_list}>
                    {postTagListsData.actorList.map((v, i) => {
                      return (
                        <li key={v.id}>
                          <ActorList actorData={v} />
                        </li>
                      );
                    })}
                  </ul>
                  <Pagination
                    pages={postTagListsData.pages}
                    style={{
                      display: postTagListsData.pages ? "flex" : "none",
                      marginTop: "121px",
                    }}
                  />
                </>
              ) : null
            ) : null}
          </section>
          {/* 우측 끝 */}
        </article>
      </main>
      <Footer />
      <IconNavigation />
    </>
  );
};

export default VoiceListPage;
