import React from "react";
import styles from "./ActorList.module.css";
import { useNavigate } from "react-router-dom";

const ActorList = ({ actorData }) => {
  const maxTagCount = 5;

  const navigate = useNavigate();
  return (
    <>
      <div
        className={styles.voicelist_wrap}
        onClick={() => navigate(`/actor/${actorData.id}`)}
      >
        <div className={styles.association_marker}>
          {actorData.aff ?? "무소속"}
        </div>
        <img
          src={`${process.env.REACT_APP_BASEURL}${actorData.photo}`}
          decoding={"async"}
          alt={actorData.name ?? "undefined"}
          height={220}
        />
        <div className={styles.voiceactor_ul_header}>
          <h3 className={styles.actorname}>{actorData.name ?? "undefined"}</h3>
          <div>
            <p>보유음원:</p>
            <p className={styles.musicsource_count}>{actorData.voiceLength}</p>
          </div>
        </div>
        <p className={styles.voiceactor_ul_introduction}>
          {actorData.profile ?? "등록된 소개가 없습니다."}
        </p>
        <ul
          className={styles.taglist_ul}
          style={{
            marginTop: "17px",
          }}
        >
          {Array.isArray(actorData.tag) === true
            ? actorData.tag.map((v, i) => {
                if (i >= maxTagCount) return null;
                return <li key={i}>{v}</li>;
              })
            : null}
        </ul>
      </div>
    </>
  );
};

export default ActorList;
