import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: "charactor",
};

const voiceCategorySlice = createSlice({
  name: "voiceCategory",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
  },
});

export const { setCategory } = voiceCategorySlice.actions;

export default voiceCategorySlice.reducer;
